.detail {
  margin-top: 18px;
  color: var(--toco-color-text-base);

  .section {
    margin-bottom: 24px;

    .header {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;    
    } 

    .history {
      border: solid 1px var(--toco-color-border-secondary);
      width: 100%;
      min-height: 152px;
      max-height: 152px;
      background-color: var(--toco-color-history-body);
      color: var(--toco-color-text-base);
      overflow: hidden;
      

      .date {
        border-bottom: solid 1px var(--toco-color-border-secondary);
        padding-left: 12px;
        padding-right: 12px;
        min-height: 32px;
        max-height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--toco-color-primary);

        .buttons {
          display: flex;
          gap: 16px;
          color: var(--toco-color-primary);
        }
      }
      .response {
        padding-left: 12px;
        padding-top: 12px;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }

    .json {
      :global(.cm-editor) {
        padding-right: 36px;
      }

      .expand {
        color: var(--toco-color-primary);
        position: relative;
        top: -83px;
        display: flex;
        justify-content: flex-end;
        padding-right: 11px;
      }
    }

    .info {
      display: flex;
      align-items: center;
      gap: 37px;

      :global(.ant5-input-outlined) {
        @mixin input-with-underline var(--toco-color-field-cname);
      }

      :global(.pair) {
        display: flex;
        align-items: center;
        gap: 14px;

        .label {
          font-size: 14px;
        }
      }

      .name {
        input {
          width: 240px;
          height: 32px;
          font-size: 14px;
        }
      }

      .desc {
        input {
          width: 192px;
          height: 24px;
          font-size: 14px;
        }
      }
    }

    .send {
      display: flex;
      gap: 8px;
      align-items: center;
      
      :global(.ant5-select-selection-item) {
        font-size: 14px !important;
      }

      .host {
        min-width: 150px;
      }
    }
  }
}

.title {
  color: var(--toco-color-text-base);
  font-size: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  :global(.anticon) {
    
  }
}

.historyDetail {
  .listItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .historyDetailItem {
    width: 100%;

    .itemTitle {
      color: var(--toco-color-text-base);
      font-size: 18px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &::before {
        content: '';
        width: 4px;
        height: 16px;
        background: var(--toco-color-primary);
      }

      :global(.anticon) {
        color: var(--toco-color-primary);
        
      }
    }

    .block {
      margin-top: 20px;
      color: var(--toco-color-text-base);
  
      .blockTitle {
        font-size: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 8px;
  
        :global(.anticon) {
          color: var(--toco-color-primary);
        }
      }
    } 
  }
}

.mockHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  color: var(--toco-color-text-base);

  .headerItem {
    width: 100%;
    display: flex;
    gap: 10px;

    :global(.toco-icon-container) {
      color: var(--toco-color-primary);
    }

    :global(.disabled) {
      color: var(--toco-color-text-disabled);
      cursor: not-allowed;
    }

    :global(.ant5-input-outlined) {
      @mixin input-with-underline var(--toco-color-field-cname);
    }

    input {
      font-size: 14px;
    }
  }

  .add {
    color: var(--toco-color-primary);
  }
}

.mockParams {
  height: 100%;
  
  .field-title {
    font-weight: bold;
    font-size: 16px;
  }

  .required {
    display: flex;
    height: 100%;
    padding-left: 10px;
  }

  .expression {
    cursor: text;

    .editor {
      width: 100%;
    }

    :global(.cm-scroller) {
      overflow: hidden;
    }

    :global(.cm-content) {
      margin-top: 3px;
    }

    height: 100%;
    @mixin input-with-underline var(--toco-color-field-cname);
  }

  .field-value {
    font-size: 14px;
    padding-left: 9px;
  }
}

.hosts {
  .list {
    margin-top: 10px;

    .buttons {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;
      height: 100%;

      .button {
        display: flex;
        align-items: center;
        padding: 5px;
        font-size: 14px;
      }

      .delete {
        color: var(--toco-color-text-secondary);

        :global(.toco-icon) {
          color: var(--toco-color-text-secondary);
        }
      }

      .active:not([disabled]) {
        color: var(--toco-color-primary);

        :global(.toco-icon) {
          color: var(--toco-color-primary);
        }
      }

      .save {
        color: var(--toco-color-primary);

        :global(.toco-icon) {
          color: var(--toco-color-primary);
        }
      }

      .test {
        color: var(--toco-color-primary);

        :global(.toco-icon) {
          color: var(--toco-color-primary);
        }
      }

      .edit {
        margin-left: 10px;
        svg {
          color: var(--toco-color-text-secondary);
        }
      }

      :global(.disabled) {
        color: var(--toco-color-text-disabled);
        cursor: not-allowed;

        :global(.toco-icon) {
          color: var(--toco-color-text-disabled);
        }
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      :global(.ant5-input) {
        @mixin input-with-underline var(--toco-color-field-cname);
      }
  
      .name {
        &::before {
          content: '';
          width: 4px;
          height: 16px;
          margin-right: 6px;
          background: var(--toco-color-primary);
        }

        &:global(.editing) {
          &::before {
            margin-top: -22px;
          }
        }

        :global(.ant5-form-item) {
          height: 56px;
          margin-bottom: 0;
        }
        
        :global(.ant5-form-item-explain-error) {
          font-size: 14px;
        }

        .nameText {
          font-size: 16px;
          margin-left: 5px;
          height: 32px;
          line-height: 32px;
          white-space: nowrap;
        }

        :global(.ant5-input) {
          font-size: 16px;
          height: 32px;
        }
  
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;
      }

      .group {
        width: 100%;
        display: flex;
        gap: 20px;
        height: 52px;
        :global(.ant5-form-item) {
          flex-basis: 25%;
        }
        .property{
          flex-basis: 25%;
        }
      }

      .form {        
        width: 100%;
        :global(.ant5-form-item-row) {
          height: 30px;
        }

        :global(.ant5-form-item-explain-error) {
          font-size: 14px;
        }

        :global(.ant5-form-item-label label) {
          font-size: 14px;
        }

        :global(.ant5-form-item-control-input-content) {
          font-size: 14px;
          
          :global(.ant5-input) {
            font-size: 14px;
          }
          :global(.ant5-input-number) {
            @mixin input-with-underline var(--toco-color-field-cname);
            font-size: 14px;
            width: 100%;
          }

          :global(.ant5-select-selector) {
            @mixin input-with-underline var(--toco-color-field-cname);
          }

          .password {
            @mixin input-with-underline var(--toco-color-field-cname);

            input {
              border-bottom: unset !important;
            }
          }
        }
      }

      .properties {
        font-size: 14px;
        .property {
          height: 30px;
          line-height: 30px;
          margin-bottom: 16px;

          .value {
            margin-left: 8px;
          }
        }
      }
    }
  }
}