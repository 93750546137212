.container {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;

}

.floatContainer {
    border-radius: var(--toco-border-radius);
    border: 1px solid var(--toco-color-border-tertiary);
    box-shadow: var(--toco-box-shadow);
    background: var(--toco-color-bg-float-bar);
}

.col {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.divider {
    top: 4px;
    height: 36px;
}

.title {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 6px;
    font-size: var(--toco-font-size);
    color: var(--toco-color-text-secondary);
    user-select: none;
}

.buttons {
    display: flex;
    gap: 10px;
    .button {
        max-height: 50px;
        min-height: 50px;
    }
}

.dropdown {
    width: 114px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    gap: 0;

    .button {
        gap: 0;
        padding: 0;
        width: 42px;
        height: 42px;
    }
}
