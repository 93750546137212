.vs-content-section-tabs {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    width: 100%;
    overflow: hidden;
    background-color: var(--toco-color-bg-base);
    border-bottom: 1px solid var(--toco-color-border-custom);

    :global(.ant5-tabs-nav) {
        height: 100%;
        margin: 0 !important;

        &::before {
            border-bottom: 0 solid transparent;
        }

        :global(>.ant5-tabs-nav-wrap) {
            :global(>.ant5-tabs-nav-list) {

                :global(.ant5-tabs-tab) {
                    margin: 0;
                    padding: 0 6px 0 14px;
                    height: 34px;
                    border-top-width: 3px;
                    border-radius: 0;
                    border-color: var(--toco-color-border-custom);
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    background-color: var(--toco-color-bg-base);

                    >div {
                        position: relative;
                        top: 0px;
                    }

                    .ant5-tabs-tab-remove {
                        display: none;

                    }
                }

                :global(>.ant5-tabs-tab-active) {
                    position: relative;
                    border-top-color: var(--toco-color-tab-active);
                    /* border-bottom-color: transparent; */

                    &::before {
                        content: "";
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        /* background-color: var(--toco-color-tab-active); */
                        background-color: transparent;
                        left: 6px;
                        border-radius: 50%;
                    }
                }

                :global(>.ant5-tabs-ink-bar) {
                    bottom: unset;
                    top: 0;
                    height: 4px;
                }
            }
        }
    }

    :global(.ant5-tabs-content-holder) { 
        padding: 0;
        width: 0;
        height: 0;        
    }
}

.vs-content-section-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: var(--toco-color-tab-word) !important; */

    .tab-type-icon {
        margin-left: 6px;
    }

    .word {
        margin: 0 8px 0 6px;
        font-size: 14px;
    }

    :global(.icon) {
        margin: 0 !important;
    }
}

.vs-content-section-tools {
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    :global(.icon) {
        font-size: 20px;
        

        +.icon {
            margin-left: 6px;
        }
    }
}

.vs-content-section-tools-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;
    width: auto;
    height: auto;
    border-radius: 4px;
    background-color: var(--toco-color-bg-resource-item);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);

    >div {
        width: 100%;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        padding: 0 16px;
        line-height: 32px;
        

        &:hover {
            background-color: rgba(0, 115, 221, 0.15);
        }
    }
}