:global(*:not(.toco-customCursor, .toco-customCursor *)) {
    &.wrapper.contextMenu * {
        cursor: url('../../../public/context-menu-cursor.svg'), context-menu !important;
    }
}

.wrapper {
    width: fit-content;

    &.hoverEnable:hover {
        outline-style: solid;
        outline-width: 1px;
        outline-color: var(--toco-color-text-tertiary);
        outline-offset: -1px;
        background-color: var(--toco-color-bg-hover);
        transition: background-color 0.2s;
    }
    &.activeEnable.contextMenu[data-menu="open"],
    &.activeEnable[data-click="active"] {
        outline-style: solid;
        outline-width: 1px;
        outline-color: var(--toco-color-primary);
        background-color: rgba(var(--toco-color-primary-value), 0.1);
        outline-offset: -1px;

        &:hover {
            outline-style: solid;
            outline-width: 1px;
            outline-color: var(--toco-color-primary);
            background-color: rgba(var(--toco-color-primary-value), 0.1);
            outline-offset: -1px;
        }
    }
}

.menu {
    min-width: 200px;
    box-shadow: var(--toco-box-shadow);
    border-radius: 4px;
    overflow: hidden;

    :global(.ant5-menu) {
        border: none !important;
        background: var(--toco-color-bg-elevated);
    }

    :global(.ant5-menu-item), :global(.ant5-menu-submenu-title) {
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        gap: 8px !important;
        margin-block: 0 !important;
        margin-inline: 0 !important;
        border-radius: 0 !important;
        padding-inline: 16px !important;
    }

    :global(.ant5-image) {
        display: flex;
        align-items: center;
    }

    :global(.ant5-menu-title-content) {
        margin-inline-start: 0 !important;
        flex: 1;
    }

    .icon {
        display: block;
        width: 16px;
        height: 16px;
    }

    .title {
        color: var(--toco-color-text-base) !important;
        font-size: 13px;
        font-weight: 500;
        background: var(--toco-color-bg-all-menu-select) !important;
        cursor: url('../../../public/default-cursor.svg'), default !important;
    }

    .menuItem {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;

        .accelerator {
            font-size: 13px;
            margin-left: 40px;
            color: var(--toco-color-text-tertiary);

            .acceleratorAlphabet {
                font-family: monospace;
            }
        }
    }
}
