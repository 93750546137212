.root {
  display: flex;
  flex-direction: column;

  .header {
    &::before {
      content: '';
      width: 4px;
      height: 18px;
      margin-right: 6px;
      background: var(--toco-color-primary);
    }
    font-size: 18px;
    font-weight: 500;
    color: var(--toco-color-text-base);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .table {
    margin-top: 12px;

    :global(.name-input) {
      height: 30px;
    }

    :global(.name-input) {
      @mixin input-with-underline var(--toco-color-field-cname);
      padding-left: 7px;
    }

    :global(.ant5-input-outlined) {
      @mixin input-with-underline var(--toco-color-field-cname);
    }

    :global(.ant5-input-number) {
      @mixin input-with-underline var(--toco-color-field-cname);
    }
  }

  .field-title {
    font-weight: bold;
    font-size: 16px;
  }

  .field-value {
    font-size: 14px;
  }

  .value-enum-title {
    display: flex;
    align-items: center;

    .value-enum-edit {
      color: var(--toco-color-primary);
      width: 20px;
      height: 20px;
    }
  }
}