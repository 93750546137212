div.wrapper {
    width: 100%;

    :global(.ant5-table-cell-row-hover) {
        background: unset !important;
    }
}

.rowHover {
    &:hover {
        outline-style: solid;
        outline-width: 1px;
        outline-color: var(--toco-color-text-tertiary);
        outline-offset: -2px;
        background: var(--toco-color-bg-hover);
    }
}
.rowActive, .rowActive:hover {
    &:global(:not(.ant5-table-row-extra)) {
        outline-style: solid;
        outline-width: 1px;
        outline-color: var(--toco-color-primary);
        outline-offset: -2px;
        background-color: rgba(var(--toco-color-primary-value), 0.1);
    }
}