.create-inner-vo {
    font-size: 14px !important;
    color: var(--toco-color-text);
  
    .innervo {
      display: flex;
      align-items: center;
      margin: 10px 0px 0px 0px;
      .name {
        border-bottom: 1px solid var(--toco-color-text-secondary);
      }
    }
    
    :global(.ant5-table-cell) {
      font-size: 14px;
    }
  
    :global(.ant5-select-selector) {
      font-size: 14px;
    }

  }