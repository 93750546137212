.sider {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;

  .main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .operation {
    height: 100%;
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    flex: 0;
    background-color: var(--toco-color-layout-bg-base);
    border-right: 1px solid var(--toco-color-border-custom);
    display: flex;
    flex-direction: column;
  }

  .sider-main {
    height: 100%;
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .sider-show {
      position: relative;
      z-index: 0;
      opacity: 1;
      transform: translateX(0);
      border-right: 1px solid var(--toco-color-border-custom);
    }

    .sider-show-app {
      border-right-width: 0;
    }

    .sider-hidden {
      position: absolute;
      z-index: -99;
      opacity: 0;
      transform: translateX(-9999);
    }

    .sider-second-container {
      position: relative;
      width: 100%;
      /* height: 352px;
      min-height: 352px;
      max-height: 352px; */
      flex: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: var(--toco-color-bg-layout);
      border-top: 1px solid var(--toco-color-border-menu);
      border-right: 1px solid var(--toco-color-border-secondary);

      > div {
        width: 100% !important;
      }

      .sider-second-scroll-container {
        height: 100%;
        width: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
      }

      .sider-second-child-container {
        flex: 1;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .sider-second-child-border-container {
        border-top: 1px solid var(--toco-color-border-menu);
      }

      .sider-second-container-main {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        padding: 4px;
      }

      .sider-second-container-title {
        width: 100%;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        width: 100%;
        padding: 0 10px;
        line-height: 32px;
        color: var(--toco-color-text-base);
        font-size: 14px;
        border-bottom: 1px solid var(--toco-color-border-menu);
        box-sizing:border-box;
        flex: 0;
      }
    }
  }

  .sider-main-hidden {
    flex: 0;
    z-index: -999;
    visibility: hidden;
    opacity: 0;
  }

  .item {
    width: 34px;
    height: 34px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 18px;
    border-radius: 4px;
    /* ; */
    color: var(--toco-color-text-secondary);

    &:hover {
      box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.1);
      /* background-color: var(--toco-color-bg-mouse-hover); */
      color: var(--toco-color-primary);
    }
  }

  .item-active {
    box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.1);
    background-color: var(--toco-color-bg-navigator-selected);
    color: var(--toco-color-primary);

    :global(.icon) {
      color: var(--toco-color-primary);
    }
  }

  .first {
    flex: 1;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .second {
    width: 100%;
    overflow: hidden;
    height: 352px;
    min-height: 352px;
    max-height: 352px;
    border-top: 1px solid var(--toco-color-border-menu);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 0;

    .bottom {
      margin-bottom: 18px;

      :global(.anticon) {
        color: var(--toco-color-text-secondary);
      }
    }
  }
}

.sider-second-container-main-item {
  height: 24px;
  padding: 0 12px;
  color: #313643;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  gap: 12px;
}

.sider-second-container-main-item-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 0;

  :global(.word) {
    margin-left: 6px;
    color: var(--toco-color-text-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sider-second-container-main-item-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0;

  :global(.word) {
    margin-right: 6px;
    color: var(--toco-color-text-base);
  }
}

.menu-operation-show-icon {
  display: block !important;

  :global(.icon) {
    display: block !important;
  }
}