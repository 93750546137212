.toco-type-select {
    width: auto;
    height: auto;
    display: flex;
    line-height: 1.6666666666666667;

    :global(.ant5-select) {
        /* border-color: var(--toco-color-input-bottom-border); */
        border-color: transparent;
        color: var(--toco-color-text-base);
        font-size: 14px;
        height: var(--toco-control-default-height);

        :global(.ant5-select-selector) {
            transition: none;
            color: inherit;
            border: none !important;
            border-width: 0 !important;
            box-shadow: none !important;
            background: transparent !important;
            border-radius: 0;
            /* border-bottom: 1px solid transparent !important; */
            border-color: inherit !important;
            font-size: inherit !important;
            padding: 2px 0;
            display: flex;
            align-items: center;
        }

        :global(.ant5-select-selection-search) {
            inset-inline-start: 0;
            inset-inline-end: 0;
        }

        :global(.ant5-select-selection-item) {
            display: flex;
            align-items: center;
            height: 100%;

            > span {
                height: 100%;
                display: inline-block;
                line-height: var(--toco-control-default-height);
                /* padding: 0 7px; */
            }
        }
    }

    .toco-type-select-input-disabled {
        color: var(--toco-color-text-base);
        :global(.ant5-select-selector) {
            color: inherit !important;
            border-width: 0 !important;
            box-shadow: none !important;
            background: transparent !important;
            border-radius: 0;
        }

        &:hover {
            background: transparent;
        }
    }

    .toco-type-select-input-border {
        border-color: var(--toco-color-field-cname);
        :global(.ant5-select-selector) {
            border-bottom: 1px solid transparent !important;
            border-color: inherit !important;
        }
    }
}

.toco-type-select-dropdown {
    list-style-type: none;
    background-color: var(--toco-color-bg-elevated);
    background-clip: padding-box;
    border-radius: 6px;
    outline: none;
    box-shadow: var(--toco-box-shadow-secondary);
    height: 240px;
    min-height: 240px;
    max-height: 240px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.toco-type-select-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 42px;
    background-color: var(--toco-color-bg-card);
    border-radius: 6px 0 0 6px;
}

.toco-type-select-anchor {
    width: 100%;
    flex: 1;
    overflow-y: auto;
}

.toco-type-select-left-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
    
    flex: 0;
}

.anchor-item {
    width: 100%;
    height: 32px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-of-type {
        border-radius: 6px 0 0 0;
    }

    &:hover {
        background-color: var(--toco-color-bg-anchor-hover);
    }
}

.anchor-item-active {
    background-color: var(--toco-color-bg-anchor-hover);
}

.toco-type-select-content {
    background-color: var(--toco-color-bg-anchor-hover);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0 6px 6px 0;
}

.toco-type-select-list {
    min-width: 120px;
    height: 100%;
    overflow: hidden;
    /* padding: 10px;
    overflow-y: auto; */

    + .toco-type-select-list {
        border-left: 1px solid var(--toco-color-border-secondary);
    }
}

.toco-type-select-list-scroll {
    overflow-y: auto;
}

.toco-type-select-list-second {
    width: 0;
    z-index: -1;
    opacity: 0;
}

.toco-type-select-list-second-active {
    /* width: 160px; */
    z-index: 0;
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.toco-type-select-item-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.item-group-title {
    width: 100%;
    height: 30px;
    flex-wrap: nowrap;
    white-space: nowrap;
    line-height: 30px;
    padding: 0 8px;
    color: var(--toco-color-text-secondary);
    user-select: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.item-group-title-empty {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--toco-color-text-tertiary);
    font-size: 10px;
}

.item-group-title-right {
    
}

.item-group-title-between {
    justify-content: space-between;
    align-items: center;
}

.item-group-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
}

.item {
    width: 100%;
    flex: 1;
    height: 30px;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    user-select: none;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        background-color: var(--toco-color-list-hover);
        color: var(--toco-color-text-base);
    }
}

.item-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 0;
}

.item-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0;
    /* min-width: 12px; */
}

.active-item {
    background-color: rgba(var(--toco-color-primary-value), 0.1);
    color: var(--toco-color-primary);
}

.hover-item {
    background-color: var(--toco-color-list-hover);
    color: var(--toco-color-text-base);
}

.item-title {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--toco-color-text-base);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toco-type-select-container {
    min-width: 120px !important;
    width: auto !important;
    padding: 0 !important;
}

.render-data-list {
    overflow-x: hidden !important;
}