.bto-field-list {
    min-width: 500px;
    max-width: 650px;
}

.bto-field-item {
    font-family: var(--toco-font-family-code);
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    > div {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    :global(.ant5-select-selection-item) {
        font-family: var(--toco-font-family-code);
        font-size: 14px;
        position: relative;
        top: 1px;
    }
}

.field-item-name {
    color: var(--toco-color-field-name);
    font-family: var(--toco-font-family-code);
    font-size: 14px;
    margin-right: 6px;
    white-space: nowrap;
    top: 2px;
    margin-left: 6px;
}

.field-item-cname {
    white-space: nowrap;
    top: 2px;
}

.incr-sign {
    color: var(--toco-color-primary);    
}

:global(.bto-field-container) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

:global(.bto-field-title) {
    border-bottom: 1px solid var(--toco-color-border-secondary);
    :global(.icon) {
        color: var(--toco-color-primary);
        font-size: 16px;
    }
    :global(.word) {
        color: var(--toco-color-text-base);
        font-size: 14px;
        margin-left: 6px;
    }
}

:global(.bto-field-group) {
    :global(.word) {
        color: var(--toco-color-text-secondary);
        font-weight: bold;
    }

    :global(.belong) {
        color: var(--toco-color-text-secondary);
        margin-left: 4px;
    }
}

:global(.bto-field-value) {
    :global(.word) {
        text-indent: 12px;
        color: var(--toco-color-text-base);
    }
}

.bto-field-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    :global(.title) {
        white-space: nowrap;
        margin-right: 6px;
    }

    :global(.search) {
        max-width: 200px;
        margin-right: 6px;
    }
}

:global(.toco-bto-detail-primary) {
    color: var(--toco-color-primary);
}