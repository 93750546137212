.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.center {
  display: flex;
  /* height: calc(100vh - 140px); */
  flex: 1;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid var(--toco-color-border-custom);
  will-change: height;
}

.side-container {
  width: 310px;
  position: relative;
  will-change: width;
  overflow: hidden;
}

.side-container-hidden {
  width: 46px !important;
  min-width: 46px !important;
  max-width: 46px !important;
}

.center-container {
  flex: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content {
  flex: 1;
  width: 100%;
  background: var(--toco-color-bg-base);
  /* height: calc(100vh - 76px); */
  /* min-height: calc(100vh - 76px); */
  /* max-height: calc(100vh - 76px); */
}

.iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background: var(--toco-color-bg-base);
}