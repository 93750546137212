.root {
  .container {
    border-bottom: 1px solid var(--toco-color-border-secondary);
    border-radius: 4px;
  }

  .sort >div {
    justify-content: center;
  }

  tr {
    height: 47px;
  }

  .addBtn {
    font-size: 14px;
    display: block;
    margin: 10px 0;
    width: 100%;
    height: 30px;
  }

  table {
    border-color: var(--toco-color-border-secondary) !important;
    border-radius: 4px;
  }

  :global(.ant-table) {
    background: transparent;
  }

  :global(.ant-table-container) {
    border-color: var(--toco-color-border-secondary) !important;
    border-radius: 4px;
  }

  th {
    &:global(.ant-table-cell) {
      font-size: 14px;
      font-weight: 500;
      background: var(--toco-color-bg-table-tr);
      color: var(--toco-color-text-base);
      border-color: var(--toco-color-border-secondary) !important;
    }

    &:global(.ant-table-cell-scrollbar) {
      box-shadow: 0 1px 0 1px var(--toco-color-border-secondary);
      border-right: none;
    }
  }

  tr:last-of-type > td {
    border-bottom: none;
  }

  tr:hover > td {
    &:global(.ant-table-cell) {
      background: var(--toco-color-bg-mouse-hover) !important;
    }
  }

  td {
    &:global(.ant-table-cell) {
      background: transparent;
      color: var(--toco-color-text-base);
      border-color: var(--toco-color-border-secondary) !important;
    }
  }

  :global(.ant-form-item) {
    color: var(--toco-color-text-base);
  }

  :global(.ant-table-cell-scrollbar) {
    box-shadow: 0 1px 0 1px var(--toco-color-border);
  }

  /* :global(.ant-form-item) {
    line-height: 30px;
  }

  :global(.ant-table) {
    line-height: 30px;
  } */

  input {
    font-size: 14px !important;
  }

  :global(.ant5-select-selection-item) {
    font-size: 14px !important;
  }

  /* :global(.last) > td {
    border-bottom-color: transparent !important;
  } */
  :global(.ant-table-placeholder) > td {
    border-bottom: unset;
  }

  :global(.ant-table-body) {
    overflow-y: auto !important;
  }

  :global(.ant-form-item-margin-offset) {
    margin-bottom: 0 !important;
  }

  :global(.toco-icon-container) {
    color: var(--toco-color-text-secondary);
  }

  :global(.selected) {
    color: var(--toco-color-primary);
  }
}

:global(.ant-popover-inner) {
  background-color: var(--toco-color-bg-base);
  border-radius: 8px;
}

:global(.ant-popover-arrow) {
  & ::before {
    background: var(--toco-color-bg-base);
  }
}

::selection {
  color: #fff;
}

