div.wrapper {
    width: 100%;

    :global(.ant5-tree-treenode-selected .ant5-tree-node-content-wrapper) {
        background-color: unset !important;
    }

    :global(.ant5-tree-node-content-wrapper) {
        background-color: unset !important;
    }

    :global(.ant5-tree-switcher:hover) {
        background-color: unset !important;
    }
}

.wrapperHover {
    :global(.ant5-tree-treenode:hover) {
        outline-style: solid;
        outline-width: 1px;
        outline-color: var(--toco-color-text-tertiary);
        outline-offset: -1px;
        background: var(--toco-color-bg-hover);
    }
}

.wrapperActive {
    :global(.ant5-tree-treenode-selected),
    :global(.ant5-tree-treenode-selected:hover) {
        outline-style: solid;
        outline-width: 1px;
        outline-color: var(--toco-color-primary);
        outline-offset: -1px;
        background-color: rgba(var(--toco-color-primary-value), 0.1);
    }
}
