.root {
  .header {  
    font-size: 14px;

    .text {
      color: var(--toco-color-text-base);
    }
  }

  .list {
    :global(.ant5-table-cell) {
      font-size: 14px;
      
    }
  }
}