.dictionary-quick-add-modal {
  :global(.ant-modal-content) {
    padding: 0;
  }

  :global(.ant5-modal-content) {
    padding: 0;
  }

  :global(.ant-modal-confirm-paragraph) {
    max-width: 100% !important;
  
    .title {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      line-height: 48px;
      padding: 0 24px;
      color: var(--toco-color-primary);
      border-bottom: 1px solid var(--toco-color-border-secondary);
  
      :global(.anticon) {
        
      }
    }
  }

  :global(.ant5-modal-confirm-paragraph) {
    max-width: 100% !important;
  
    .title {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      line-height: 48px;
      padding: 0 24px;
      color: var(--toco-color-primary);
      border-bottom: 1px solid var(--toco-color-border-secondary);
  
      :global(.anticon) {
        
      }
    }
  }
  
}

