.mo-detail {

  .trigger {
    color: var(--toco-color-text-base);
    font-size: 14px;
    margin-bottom: 24px;

    .funcName {
      color: var(--toco-color-primary);
    }
  }

  .form {
    :global(.ant5-form-item) {
      margin-bottom: 24px;
    }
    
    :global(.ant5-form-item-label) {
      width: 80px;
      
      label {
        font-size: 14px;
      }
  
      label:not(:global(.ant5-form-item-required)) {
        margin-left: 10px;
      }
    }

    .dead {
      :global(.ant5-form-item-label) {
        width: 100px;
      }
    }

    :global(.ant5-form-item-control-input-content) {
      font-size: 14px;

      :global(.ant5-input-affix-wrapper) {
        @mixin input-with-underline var(--toco-color-field-cname);
      }

      .cname {
        @mixin input-with-underline var(--toco-color-field-cname);
      }

      :global(.ant5-select-selector) {
        @mixin input-with-underline var(--toco-color-field-cname);
      }

      :global(.enhanced-input-container) {
        padding-left: 7px;
        @mixin input-with-underline var(--toco-color-field-cname);
      }

      :global(.ant5-input-outlined) {
        @mixin input-with-underline var(--toco-color-field-cname);
      }
    }

    :global(.ant5-form-item-explain) {
      font-size: 14px;
    }

    :global(.ant5-select-selector) {
      font-size: 14px;
    }

    input {
      font-size: 14px;
    }
  }

  .buttons {
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;

    .button {
      display: flex;
      align-items: center;
      padding: 5px;
      font-size: 14px;
    }

    .edit {
      svg {
        color: var(--toco-color-text-secondary);
      }
    }
  }

  .extra {
    font-size: 14px;
    color: var(--toco-color-text-secondary);

    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 10px;
    margin-bottom: 32px;

    .delayHint {
      color: var(--toco-color-text-tertiary);
    }

    :global(.ant5-input-number) {
      @mixin input-with-underline var(--toco-color-field-cname);
      font-size: 14px;
      height: 24px;

      :global(.ant5-input-number-input) {
        line-height: 16px;
      }
    }

    :global(.ant5-input-number-suffix) {
      font-size: 14px;
    }

    :global(.ant5-input-number-outlined) {
      border: unset;
      background: unset;
      box-shadow: unset;
    }

    :global(.ant5-checkbox-wrapper) {
      gap: 4px;
      font-size: 14px;
    }
  }

  .public {
    padding-right: 15px;

    :global(.ant5-checkbox-wrapper) {
      gap: 4px;
      font-size: 14px;
    }
  }

  .title {
    font-size: 14px;
  }

  .subed {
    
  }
}