div.hover-row.status-bar {
    display: none !important;
}

.express-editor-wrapper {
    border-bottom: 1px solid #d9d9d9;
    transition: all 0.3s;
}

.express-editor-readonly-wrapper {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.express-editor-readonly-wrapper .monaco-mouse-cursor-text {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.express-editor-readonly-wrapper .monaco-mouse-cursor-text .mtk1 {
    color: #00000040;
}

.express-editor-readonly-wrapper:hover {
    border-color: #d9d9d9;
}

.express-editor-wrapper:focus-within {
    border-right-width: 1px !important;
}

.express-editor-readonly-wrapper:focus-within {
    border-color: #d9d9d9;
    box-shadow: none;
}

.ant5-form-item-has-error :not(.express-editor-readonly-wrapper).express-editor-wrapper {
    border-color: #ff4d4f;
}

.ant5-formily-item-error :not(.express-editor-readonly-wrapper).express-editor-wrapper:focus-within {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    box-shadow: 0 2px 0px 0px rgba(255, 77, 79, 0.2);
}