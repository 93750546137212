.form-container {
  position: relative;


  .form-btn-submit {
    color: var(--toco-color-primary);
    border: none;
    box-shadow: none;
    letter-spacing: 0px;
    font-size: 14px;
  }

  .form-btn-reset {
    color: var(--toco-color-error);
    border: none;
    box-shadow: none;
    letter-spacing: 0px;
    font-size: 14px;
  }

  :global(.ant5-form-item-label) {
    width: 100px;
    
    label {
      font-size: 14px;
    }

    label:not(:global(.ant5-form-item-required)) {
      margin-left: 10px;
    }
  }

  .info {
    display: flex;
    height: 100%;
    color: var(--toco-color-primary);
    padding-left: 9px;
  }
}

.vs-form-btn {
  display: flex !important;
  justify-content: flex-end;

  >button {
    margin-left: 10px;
  }
}

.entity-dto-list-parent {
  display: flex;

  .expand {
    font-size: 12px;
    
    height: fit-content;
  }
}

.entity-dto-list.collapsed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

.entity-dto-list.expanded {
  white-space: normal;
  max-width: calc(100% - 50px);
}

.entity-dto-list {

  .expand {
    font-size: 12px;
    
  }
}


.dto-field-row {
  >td {
    padding: 6px 8px !important;
  }  
}

.dto-detail-anchor {

  border-bottom: 1px solid var(--toco-color-border-secondary);

  :global(.ant5-anchor) {
    padding: 0px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 36px;

    background-color: #ffffff;

    :global(.ant5-anchor-ink) {
      display: none;
    }

    :global(.ant5-anchor-link-title) {
      text-align: center;
      color: var(--toco-color-tab-word) !important;
    }

    :global(.ant5-anchor-link-title-active) {
      color: var(--toco-color-primary) !important;
      border-bottom: 1px solid #3676FE
    }

    :global(.ant5-anchor-link) {
      display: inline-block;
      margin-right: 10px;
      width: 120px;
      padding: 7px 0px 7px 16px; 
    }
  }

}

.create-inner-vo-modal {
  font-size: 14px !important;

  .innervo {
    display: 'flex';
    margin: 20px 4px 0px 4px;
    .name {
      border-bottom: 1px solid var(--toco-color-text-secondary);
    }
  }
  
  :global(.ant5-table-cell) {
    font-size: 14px;
  }

  :global(.ant5-modal-body) {
    font-size: 14px;
  }
}


.vo-reverse-extend{
  font-size: 14px;

  :global(.ant5-table-cell) {
    font-size: 14px;
  }
}