.error {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  color: var(--toco-color-text-base);

  span {
    
    color: var(--toco-color-primary);
  }
}

.list-page {
  height: 100%;
  
  .error {
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    color: var(--toco-color-text-base);
  
    span {
      
      color: var(--toco-color-primary);
    }
  }

  .mo-table {
    margin-top: 12px;
    height: calc(100% - 12px);

    .extra {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-right: 8px;

      :global(.ant5-btn-sm) {
        height: 26px;
      }
    }

    .btn {
      color: var(--toco-color-primary);
    }

    .group-row-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    .group-row-icon {
      margin-right: 10px;
    }
  
    .group-row-title {
      font-size: 16px;
      color: var(--toco-color-text-base);
      font-weight: 500;
  
      > b {
        color: var(--toco-color-primary);
      }
    }
  
    .group-row-desc {
      font-size: 12px;
      color: var(--toco-color-text-base);
      margin-left: 10px;
    }
  
    .group-row-extra {
      font-size: 12px;
      color: var(--toco-color-text-secondary);
      margin-left: 10px;
    }
  }
}

.mo-page {
  height: 100%;

  .tabs {
    height: 100%;

    :global(.ant5-tabs-tab) {
      margin-left: 24px;
      font-size: 14px;
    }
    :global(.ant5-tabs-content) {
      height: 100%;
    }
    :global(.ant5-tabs-tabpane) {
      height: 100%;
    }
    margin-bottom: 10px;
  }
}