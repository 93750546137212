.toco-blood-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  transition: width 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  min-width: 250px;
  background-color: var(--toco-color-bg-layout);

  .toco-blood-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    padding: 0 8px;
    border-bottom: 1px solid var(--toco-color-border-secondary);

    .toco-blood-header-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;  
    }

    .toco-blood-module-name {
      color: var(--toco-color-primary);
      margin-left: 6px;
      font-size: 16px;
    }

    .toco-blood-header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;  
    }

    .header-expand {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--toco-color-text-base);
      background: #1361B31A;
      padding: 0 6px;
      border-radius: 3px;
      

      .expand-word {
        margin-left: 4px;
        font-size: 14px;
      }
    }


    .header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      min-width: 20px;
      

      :global(.icon) {
        color: var(--toco-color-position-icon);
      }
    }

    .header-icon-active {
      :global(.icon) {
        color: var(--toco-color-primary);
      }
    }
  }

  .toco-blood-content {
    height: 0;
    flex: 1;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .toco-blood-block-tree {
    background-color: var(--toco-color-bg-base) !important;
    overflow-x: auto;
    height: 100%;
    width: 100%;

    .ant5-tree-switcher-leaf-line::after {
      border-bottom-width: 0;
    }

    :global(.ant5-tree-treenode) {
      &:hover {
        background-color: var(--toco-color-bg-tree-hover);

        .menu-item-right {
          :global(.icon) {
            display: block;
          }
        }
      }
    }

    :global(.ant5-tree-list-holder) {
      >div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    :global(.ant5-tree-list-holder-inner) {
      flex: 1;
      display: table-footer-group !important;
    }

    :global(.ant5-tree-node-content-wrapper) {
      display: flex;

      &:hover {
        background-color: transparent !important;
      }
    }

    :global(.ant5-tree-treenode-leaf-children-selected) {
      /* background-color: rgba(var(--toco-color-primary-value), 0.1); */
      background-color: var(--toco-color-bg-base);
    }

    :global(.ant5-tree-treenode) {
      border: 1px solid transparent;
    }

    :global(.ant5-tree-treenode-selected) {
      background-color: rgba(var(--toco-color-primary-value), 0.1);
      color: var(--toco-color-text-base);

      .label {
        color: var(--toco-color-text-base);
        font-weight: 500;
      }
    }

    /* :global(.ant5-tree-treenode-leaf-selected) {
      background-color: rgba(var(--toco-color-primary-value), 0.1);
    } */

    :global(.ant5-tree-node-selected) {
      background-color: transparent !important;
    }

    :global(.ant5-tree-switcher) {
      display: none;
    }

    :global(.ant5-tree-iconEle) {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }

  .toco-blood-title-module-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 50px;

    >div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .menu-item-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;
    }

    .menu-item-right {
      position: absolute;
      right: 12px;

      :global(.icon) {
        
        display: none;

        +.icon {
          margin-left: 8px;
        }
      }

      .show-icon {
        display: block;
      }
    }

    .label {
      white-space: nowrap;
      font-size: 14px;
      color: var(--toco-color-text-base);
      margin-left: 6px;
    }

    .collapse-icon {
      margin-left: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dir-label {
      font-weight: 400;
      font-size: 14px;
      color: var(--toco-color-module);
      margin-left: 6px;
    }

    .root-label {
      font-weight: 400;
      color: var(--toco-color-text-base);
      font-size: 14px;
    }

    .root-active-label {
      font-weight: 500;
      color: var(--toco-color-text-base);
      font-size: 15px;
    }

    .leaf-label {
      font-weight: 400;
      color: var(--toco-color-text);
      font-size: 14px;
      font-style: italic;
    }

    .custom-dir-label {
      color: var(--toco-color-text-secondary);
      font-style: italic;
      font-size: 12px;
    }

    .extra-label {
      margin-left: 6px;
      color: var(--toco-color-text-tertiary);
      font-style: italic;
      font-size: 12px;
    }
  }

  .toco-blood-title-module-block-active {
    .label {
      font-style: normal;
    }
  }
}