#root {
    height: 100%;

    .app-root {
        height: 100%;
    }
}

b {
    color: var(--toco-color-primary);
    background-color: #fffd52;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    background-color: transparent;
    border-radius: 3px;
}

.ant5-tree-list-scrollbar {
    width: 6px !important;
    background-color: transparent;
    border-radius: 3px;
}

*::-webkit-scrollbar-thumb, .ant5-tree-list-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--toco-color-scrollbar) !important;
} 


*::-webkit-scrollbar-track {
    background-color: transparent;
} 


*::-webkit-scrollbar-corner {
    background-color: transparent;
} 


*::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

* {
    user-select: none;
}


*:not(.toco-customCursor, .toco-customCursor *),
*.toco-defaultCursor,
*.toco-defaultCursor *,
iframe body * {
    cursor: url('../../public/default-cursor.svg'), default !important;
    user-select: none;
}

body {
    overflow: hidden;
}

.ant5-table, .ant5-table-body {
    scrollbar-color: var(--toco-color-scrollbar) transparent !important;
    overflow-y: auto !important;
}

.ant5-table-body::-webkit-scrollbar {
    width: 6px !important;
    height: 5px!important;
    background-color: transparent!important;
    border-radius: 3px!important;
}

.ant5-table-body::-webkit-scrollbar-thumb {
    border-radius: 10px!important;
    background-color: var(--toco-color-scrollbar)!important;
}

.ant5-table-body::-webkit-scrollbar-track {
    background-color: transparent!important;
}
.ant5-table-body::-webkit-scrollbar-corner {
    background-color: transparent!important;
}
.ant5-table-body::-webkit-scrollbar-track-piece {
    background-color: transparent!important;
}

.ant5-btn-primary {
    color: var(--toco-color-btn-text);
    background: var(--toco-color-primary);
}

.ant5-btn-primary:not(:disabled):not(.ant5-btn-disabled):hover {
    color: var(--toco-color-btn-text);
}

.rpc-list-page {
    .ant5-table-tbody-virtual-scrollbar-horizontal {
        display: none !important;
    }
}

.ant-form-item-control-input-content {
    .ant5-input-affix-wrapper {
        height: var(--toco-control-default-height);
    }
}

.ant5-btn-primary {
    box-shadow: none;
    border-radius: 2px;
}

.toco-modal {
    .ant5-modal-content {
        padding: 0 !important;
    }

    .ant5-modal-header {
        height: 48px;
        padding: 0 24px;
        border-bottom: 1px solid var(--toco-color-border-secondary);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0 !important;
    }

    .ant5-modal-title {
        font-size: 16px;
        font-weight: 500;
        color: var(--toco-color-text-base);
    }

    .ant5-modal-close {
        top: 10px;
        right: 10px;
    }

    .ant5-modal-body {
        padding: 24px;   
        max-height: unset !important;
    }

    .ant5-modal-footer {
        height: 56px;
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        width: 100%;
        justify-content: flex-end;
        border-top: 1px solid var(--toco-color-border-secondary);
        padding: 0 24px;
        gap: 8px;
    }
}