.footer-content {
    /* border-top: 1px solid var(--toco-color-border-custom); */
    background-color: var(--toco-color-bg-base);
    height: 100%;

    :global(.ant5-table-wrapper) {
        width: 100%;
        overflow: auto;
    }

    :global(.ant5-table-wrapper .ant5-table) {
        font-size: 13px;
        line-height: 24px;
        background-color: transparent;
    }

    :global(.ant5-table-wrapper .ant5-table-tbody >tr >td) {
        border-bottom: none;
        padding: 5px 4px;
    }

    :global(.ant5-table-wrapper .ant5-table .ant5-table-thead >tr >th) {
        line-height: 1;
        background-color: var(--toco-color-bg-hover);
    }

    :global(.ant5-table-placeholder) {
        text-align: center;
      }

    .log-content-header {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px;
        height: 36px;
        padding: 0 16px;
        background-color: var(--toco-color-bg-footer-top);
        border-bottom: 1px solid var(--toco-color-border-custom);
    }

    .log-content-header-main {
        color: var(--toco-color-text-base);
        padding-left: 5px;
    }

    .log-content-header-extra {
        display: flex;
        align-items: center;
        margin-left: 12px;
        font-size: 13px;
        color: var(--toco-color-text-secondary);
    }

    .log-content-header-tail {
        display: flex;
        margin-left: auto;
        gap: 5px;

        >Button {
            border: none;
            background-color: transparent;
            box-shadow: none;
        }
    }

    .log-content {
        padding: 0 16px;
        display: flex;
        height: calc(100% - 48px);
    }

    .compile-detail, .log-detail{
        color: var(--toco-color-text);
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        user-select: text;
    }

    .object{
        color: var(--toco-color-primary);
        font-size: 13px;
        padding: 0px;
    }

    .reverted {
        color: var(--toco-color-text-tertiary) !important;

        .object{
            color: var(--toco-color-text-tertiary) !important;
        }
    }

    .fix {
        padding-left: 8px;
        color: var(--toco-color-text-tertiary) !important;
    }


    .point {
        background-color: var(--toco-color-primary);
        border-radius: 50%;
        border: 1px solid var(--toco-color-primary);
        width: 12px;
        height: 12px;
    }

    .position-active {
        color: var(--toco-color-primary);
    }

    .position {
        color: var(--toco-color-position-icon);
    }

    .log-status{
        padding-top: 0;
        padding-bottom: 0;
    }


    .log-status-item-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        height: 100%;
        padding: 0 4px;
    }

    .log-status-item {
        width: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


        .status-line {
            width: 1px;
            height: 100%;
        }

        .solid {
            border-left: 1px solid;
        }

        .dashed-published {            
            border-left: 1px solid transparent;
            border-image: linear-gradient(to bottom, #FF9500 50%, rgba(255, 255, 255, 0) 0%);
            border-image-slice: 1;
            border-image-width: 1px;
            border-image-repeat: round;
        }

        .dashed-code {            
            border-left: 1px solid transparent;
            border-image: linear-gradient(to bottom, #4CAB1D 50%, rgba(255, 255, 255, 0) 0%);
            border-image-slice: 1;
            border-image-width: 1px;
            border-image-repeat: round;
        }


        .published-color {
            border-left-color: #FF9500;
        }

        .code-color {
            border-left-color: #4CAB1D;
        }

    }
}

.footer-button {

    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 4px;
    
    .icon{
        margin-right: 2px;
    }

    .active {
        color: var(--toco-color-primary);
    }

    .inactive {
        color: var(--toco-color-text);
    }
    
}



.extra-info-page {
    padding: 0 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--toco-color-border-secondary);
    font-size: 13px;
    background-color: var(--toco-color-bg-layout);

    .compile-detail{
        color: var(--toco-color-text);
        font-size: 13px;
    }

    .expandable {
        color: var(--toco-color-expand-field-type);
    }

    .fix {
        padding-left: 8px;
        color: var(--toco-color-text-tertiary) !important;
    }

    .collapse {
        color: var(--toco-color-primary);
    }

    .collapse:hover {
        color: var(--toco-color-primary) !important;
    }

    :global(.ant5-tabs-top){
        height: 100%;
    }

    :global(.ant5-tabs-content-holder){
        flex: 1;
        overflow: auto;
    }

    :global(.ant5-table-wrapper .ant5-table-tbody >tr >td) {
        border-bottom: none;
        padding: 5px 4px;
    }

    :global(.ant-table-cell) {
        line-height: 24px;
    }

    :global(.ant5-table-wrapper .ant5-table) {
        font-size: 13px;
        line-height: 1;
        background-color: transparent;
    }
    :global(.ant5-tabs-content) {
        height: 100%;
    }
    :global(.ant5-tabs-tabpane) {
        height: 100%;
    }
}

