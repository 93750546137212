.text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.warning {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-underline-offset: 3px;
  text-decoration-color: orange;
}

.error {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-underline-offset: 3px;
  text-decoration-color: red;
}