.root {
  padding-right: 15px;

  :global(.ant5-input-affix-wrapper) {
    @mixin input-with-underline var(--toco-color-field-cname);
  }

  :global(.ant5-select-selector) {
    @mixin input-with-underline var(--toco-color-field-cname);
    background: transparent !important;
  }

  :global(.name-input) {
    @mixin input-with-underline var(--toco-color-field-cname);
    padding-left: 7px;
  }

  :global(.ant5-input-outlined) {
    @mixin input-with-underline var(--toco-color-field-cname);
  }

  :global(.ant5-input-number-affix-wrapper) {
    @mixin input-with-underline var(--toco-color-field-cname);
  }
}