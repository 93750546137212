/* Input 没边框样式 */
@define-mixin input-with-underline $color {
    color: $color !important;
    border: none !important;
    box-shadow: none !important;
    background: transparent;
    border-radius: 0;
    border-bottom: 1px solid $color !important;
}

/* Select 没边框样式 */
@define-mixin select-with-underline $color, $font-size: 14px {
    &:global(.ant5-select), :global(.ant5-select) {
        position: relative;
        &::after {
            position: absolute;
            bottom: 0;
            content: '';
            height: 1px;
            width: 100%;
            background: $color;
        }
    }

    :global(.ant5-select-selection-search) {
        inset-inline-start: 0;
    }

    :global(.ant5-select-selector) {
        border: none !important;
        box-shadow: none !important;
        background: transparent;
        border-radius: 0;
        padding-left: 0;
    }

    :global(.ant5-select-arrow) {
        color: $color !important;
    }

    :global(.ant5-select-selection-item) {
        font-size: $font-size;
        color: $color;
    }

    &:global(.ant5-select-open .ant5-select-selection-item) {
        color: var(--toco-color-text-placeholder);
    }

    :global(.ant5-select-selection-placeholder) {
        font-size: $font-size;
    }
}

/* Cascader 没边框样式 */
@define-mixin cascader-with-underline $color, $font-size: 14px {
    &:global(.ant5-cascader), :global(.ant5-cascader) {
        width: fit-content;
    }
    @mixin select-with-underline $color, $font-size;
}