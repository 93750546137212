.toco-grouped-table {
  display: flex;
  flex-direction: column;

  .group-table-container {
    flex: 1;
    display: flex;
    .group-table {
      width: 100%;
      height: 0;
    }
  }

  .cell {
    height: 100%;
  }

  :global(.from) {
    > div {
      align-items: start;
    }
  }

  .group-row-buttons {
    margin-right: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 0;
  }

  .group-row {
    background-color: var(--toco-color-bg-table-tr);
    
    .group-row-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .group-row-start {
        display: flex;
        align-items: center;
        gap: 8px;

        .group-row-title {
          font-size: 16px;
          color: var(--toco-color-text-base);
          font-weight: 500;
      
          > b {
            color: var(--toco-color-primary);
          }
        }
      }
    }
  }

  .table-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .table-filter-title {
    font-size: 16px;
    color: var(--toco-color-text-base);
    font-weight: 500;
    padding-left: 12px;
    position: relative;
    margin-bottom: 12px;
    display: flex;
    gap: 10px;
    width: 100%;

    &::before {
      position: absolute;
      width: 4px;
      height: 16px;
      background-color: var(--toco-color-primary);
      top: 5px;
      left: 0;
      content: "";
    }
  }

  .table-filter-item-container-content {
    flex: 1;
  }

  .table-filter-content {
    display: block;
  }

  :global(.ant5-table-thead > tr > th) {
    background-color: var(--toco-color-bg-table-tr);
    font-size: 14px;
    font-weight: 500;
  }

  .table-filter-item-container {
    display: flex;
    justify-content: flex-start;
    color: var(--toco-color-text-base);
    min-height: 36px;
  }

  .table-filter-item-container-title {
    padding: 4px 4px 4px 0;
    margin-right: 10px;
    position: relative;

    &::after {
      content: ":";
      position: absolute;
      top: 4px;
      right: -2px;
    }
    /* display: flex;
    align-items: center; */
  }

  .tag-filter {
    display: flex;
    flex-wrap: wrap;
    max-height: 72px;
    overflow-y: auto;

    :global(.ant5-tag-checkable) {
      font-size: 14px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: var(--toco-color-primary);
      }
    }

    :global(.ant5-tag-checkable-checked) {
      color: var(--toco-color-primary);
      background-color: rgba(var(--toco-color-primary-value), 0.1)
    }
  }

  .table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 10px;
    min-height: 28px;
    max-height: 28px;
    flex: 0;

    &:global(.noFilter) {
      margin-top: 0;
    }
  }

  .table-header-container-left {
    .title {
      font-size: 16px;
      color: var(--toco-color-text-base);
      font-weight: 500;
      padding-left: 12px;
      position: relative;

      &::before {
        position: absolute;
        width: 4px;
        height: 16px;
        background-color: var(--toco-color-primary);
        top: 2px;
        left: 0;
        content: "";
      }
    }
  }

  .table-header-container-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 28px;
    min-height: 28px;
    max-height: 28px;

    .operation {
      margin-right: 12px;

      :global(button) {
        height: 28px;
        
        + button {
          margin-left: 12px;
        }
      }
    }
    
    :global(.ant5-input-affix-wrapper) {
      height: 28px;  
    }
  }

  :global(.group-table-left-cell) {
    padding-left: 16px !important;
  }
  
  :global(.ant5-table-placeholder) {
    text-align: center;
  }

  :global(.row-clickable) {
    
  }

  :global(.in-group) {
    :global(.group-table-first-left-cell) {
      padding-left: 43px !important;
    }
  }

  :global(td.ant5-table-cell) {
    color: var(--toco-color-text-base);
    font-size: 14px;
    font-weight: 400;
    min-height: 32px;
    max-height: 32px;
    line-height: 24px;

    b {
      color: var(--toco-color-primary);
    }
  }

  :global(div.ant5-table-cell) {
    color: var(--toco-color-text-base);
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;

    min-height: 39px;
    b {
      color: var(--toco-color-primary);
    }
  }
}