.toco-field-type {
    color: var(--toco-color-field-type);
}

.toco-field-name {
    color: var(--toco-color-field-name);
}

.toco-field-url {
    color: var(--toco-color-text-secondary);
}

.toco-method-display {

    .toco-method-return-type {
        color: var(--toco-color-field-type);

        .expandable {
            color: var(--toco-color-expand-field-type)
        }
    }

    .toco-method-param-name {
        color: var(--toco-color-field-name);
        overflow: hidden;

        .expandable {
            color: var(--toco-color-expand-field-type)
        }

    }

    .toco-method-name {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
        text-align: left;
    }
}