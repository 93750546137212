.toco-list-page {
    padding: 12px;
    height: 100%;

    .toco-list-page-table {
      height: 100%;
    }
  
    .group-row-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    .group-row-icon {
      margin-right: 10px;
    }
  
    .group-row-title {
      font-size: 16px;
      color: var(--toco-color-text-base);
      font-weight: 500;
      line-height: 18px;

      > b {
        color: var(--toco-color-primary);
      }
    }
  
    .group-row-desc {
      font-size: 12px;
      color: var(--toco-color-text-base);
      margin-left: 10px;
      line-height: 16px;
    }
  
    .group-row-extra {
      font-size: 12px;
      color: var(--toco-color-text-secondary);
      margin-left: 10px;
      line-height: 16px;
    }

    .group-row-extra-has-brace {
      margin-left: 5px;
    }
  
    .group-row-expand-icon {
      padding-left: 10px;
    }
  
    .group-row-expand-icon-has-brace {
      padding-left: 5px;
    }
  }
  

.toco-underline-table {
  .ant5-input-affix-wrapper {
    @mixin input-with-underline var(--toco-color-field-cname);
  }

  .enhanced-input {
    @mixin input-with-underline var(--toco-color-field-cname);
  }
}

.toco-section-sub-item{
  padding-bottom: 32px;
}

.toco-section-sub-item-header{
  padding-bottom: 16px;

  .title{
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.toco-section-header {
  display: flex;
  flex-direction: column;
  color: var(--toco-color-text-base);
  margin: 24px 0 16px 0;

  h1 {
    @mixin head-h1;
  }

  h2 {
    @mixin head-h2;
  }


  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    h1 {
      margin-bottom: 0;
    }

    .subTitle {
      font-size: 14px;
      /* color: var(--toco-color-text-base); */
      color: var(--toco-color-text-secondary);
    }
    
    .title {
      font-size: 16px;
      font-weight: 500;

      .stick {
        width: 4px;
        height: 18px;
        background-color: var(--toco-color-primary);
      }
    }

    .extra {
      display: flex;
      gap: 8px;
    }
  }
}


.toco-top-section {
  margin-top: 0;
}