.qto-query-detail {

    color: var(--toco-color-text-base) !important;
    font-size: 14px !important;
    white-space: nowrap;
    
    :global(.name-input){
        width: unset !important;
    }

    :global(.enhanced-input){
        min-width: 160px;
        @mixin input-with-underline var(--toco-color-text-base);
    }

    .sub-title{
        font-size: 14px;
        font-weight: 500;
    }

    /* .sub-title-item{
        padding: 32px 0 16px 0px;
    } */

    .sub-item-header{
        padding-bottom: 16px;
    }

    .sub-item-body{
        padding-bottom: 32px;
    }

    .desc-input{
        border: none;
        border-bottom: 1px solid var(--toco-color-text-base);
        box-shadow: none;
        border-radius: 0;
    }

    :global(.ant5-checkbox-wrapper) {
       font-size: 14px;
    }
    /* :global(.ant5-input){
        border: none;
        border-bottom: 1px solid var(--toco-color-text-base);
        box-shadow: none;
    } */

    .page-mode{
        :global(.toco-icon-container) {
            display:inherit !important;
        }
    }

    h2{
        margin-bottom: 0;
    }
}

.info {
    display: flex;
    height: 100%;
    color: var(--toco-color-primary);
    padding-left: 9px;
  }