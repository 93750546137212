.toco-resource-manage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    
    .toco-resource-manage-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
    }

    .item-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
    }

    .item-main {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
    }
}

.toco-resource-manage-list-item {
    width: 120px;
    height: 120px;
    border-radius: 2px;
    /* background-color: #F7F8FA; */
    border: 1px dashed #D1D1D1;
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    :global(.ant5-image) {
        overflow: hidden;
    }

    .item-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        width: 100%;
    }

    .item-image-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: var(--toco-color-text-base);
        z-index: 9;
        padding: 0 12px;
    }

    .item-content-word {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .item-content-url {
        overflow-x: hidden;
        flex: 1;
        width: 100%;
        text-overflow: ellipsis;
        word-break: break-word;
    }
}

.toco-resource-link-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: var(--toco-color-bg-resource-item);
    height: 84px;
    padding: 0 16px;
    gap: 96px;
}

.toco-resource-link-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex: 1;
}

.link-icon {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-name {
    color: var(--toco-color-text-base);
}

.link-url {
    color: #4274ED;
}

.toco-resource-link-content-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
}

.toco-resource-item-add {
    width: 120px;
    height: 120px;
    border: 1px dashed var(--toco-color-text-tertiary);
    border-radius: 2px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 0;
    background-color: var(--toco-color-bg-form-item);
    gap: 4px;

    .toco-resource-upload-item-title {
        height: 28px;
        padding: 0 12px;
    }

    .toco-resource-upload-item {
        padding: 0 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        height: 28px;
        width: 100%;

        &:hover {
            background-color: #0000000D;
        }
    }
}

.toco-resource-item-file-add {
    :global(.ant5-upload) {
        width: 100% !important;
        margin: 0;
        background-color: var(--toco-color-bg-resource-item);
    }
}

.toco-resource-item-link-add {
    width: 100%;
    height: 89.25px;
    margin-inline-end: 4px;
    margin-bottom: 4px;
    text-align: center;
    vertical-align: top;
    background-color: rgba(49, 54, 67, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    transition: border-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.file-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex: 1;

    :global(.ant5-image) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 36px;
        min-height: 36px;
        max-width: 36px;
        max-height: 36px;
    }

    :global(.ant5-input) {
        flex: 1;
    }
}

.file-pic-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: var(--toco-color-bg-resource-item);
    padding: 0 16px;
    gap: 96px;
}
.file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: var(--toco-color-bg-resource-item);
    height: 60px;
    padding: 0 16px;
    gap: 96px;
}

.file-container-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex: 1;
}

.file-container-item-block {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toco-resource-usage {
    -webkit-app-region: no-drag;
    padding: 0 !important;
    width: 28px;
}