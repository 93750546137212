.error {
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    color: var(--toco-color-text-base);
  
    span {
      
      color: var(--toco-color-primary);
    }
  }
  
  .rpc-list-page {
    padding: 12px;
    height: 100%;
  
    .count {
      color: var(--toco-color-primary);
    }
  
    .rpc-table {
      height: 100%;
  
      .extra {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-right: 8px;
  
        :global(.ant5-btn-sm) {
          height: 26px;
        }
      }
  
      .btn {
        color: var(--toco-color-primary);
      }
  
  
      .from {
        color: var(--toco-color-primary);
        
        margin-left: 5px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  
    .group-row-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    .group-row-icon {
      margin-right: 10px;
    }
  
    .group-row-title {
      font-size: 16px;
      color: var(--toco-color-text-base);
      font-weight: 500;
  
      > b {
        color: var(--toco-color-primary);
      }
    }
  
    .group-row-expand {
      margin-left: 10px;
    }
  
    .group-row-desc {
      font-size: 12px;
      color: var(--toco-color-text-base);
      margin-left: 10px;
    }
  
    .group-row-extra {
      font-size: 12px;
      color: var(--toco-color-text-secondary);
      margin-left: 10px;
    }
  
    :global(.ant5-select-selection-item) {
      font-size: 14px;
    }
  
    :global(.ant5-table-wrapper .ant5-table-thead .api-from) {
      text-align: left !important;
      padding-left: 20px !important;
  }
  
  }