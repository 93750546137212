.toco-menu-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  transition: width 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  min-width: 250px;
  background-color: var(--toco-color-bg-layout);
  padding-bottom: 4px;

  .toco-menu-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--toco-color-layout-bg-base);
    border-bottom: 1px solid var(--toco-color-border-custom);
    
    flex: 0;
    height: 48px;

    .first {
      padding: 0 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 48px;
      box-sizing: border-box;
    }

    .header-filter {
      height: 28px;
      min-width: 150px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .toco-menu-search {
      flex: 1;
      border-radius: 2px;
      margin-right: 10px;
    }

    .header-location-btn {
      height: 18px;
      font-size: 12px;
      color: #fff;
      border-radius: 4px;
      padding: 0 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      > span {
        margin-left: 2px;
      }
    }

    .placeholder {
      width: 100%;
      height: 100%;
    }

    .title {
      font-weight: bold;
      padding: 0 12px;
    }

    .header-icon-group {
      display: flex;
    }

    .header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      min-width: 20px;
      flex: 1;
      
      :global(.icon) {
        color: var(--toco-color-position-icon);
      }
    }

    .header-icon-active {
      :global(.icon) {
        color: var(--toco-color-primary);
      }
    }
  }

  .toco-menu-content {
    height: 0;
    flex: 1;
    overflow: auto;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .toco-menu-block {
      /* flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column; */
      width: 100%;
      height: 100%;
      overflow-x: auto;

      > div {
        width: fit-content !important;
        min-width: 100%;
      }
    }
  }
}

.toco-menu-block-tree {
  background-color: var(--toco-color-bg-base) !important;
  flex: 1;
  height: 0;
  width: 100%;

  :global(*) {
    cursor: url('../../../../public/default-cursor.svg'), default !important;
  }

  .ant5-tree-switcher-leaf-line::after {
    border-bottom-width: 0;
  }

  :global(.ant5-tree-treenode) {
    border: 1px solid transparent;

    &:hover {
      background-color: var(--toco-color-bg-tree-hover);
      .menu-item-right {
        :global(.icon) {
          display: block;
        }
      }
    }
  }

  :global(.ant5-tree-title) {
    width: 100%;
  }

  :global(.ant5-tree-list) {
    min-height: 100%;
    max-height: 100%;
    display: flex;
    position: unset !important;
  }

  :global(.ant5-tree-list-holder) {
    min-height: 100%;
    max-height: 100%;
    flex: 1;

    > div {
      /* width: fit-content; */
      overflow: unset !important;
    }
  }

  :global(.ant5-tree-list-holder-inner) {
    /* width: fit-content; */
    position: relative !important;
  }

  :global(.ant5-tree-node-content-wrapper) {
    display: flex;

    &:hover {
      background-color: transparent !important;
    }
  }

  :global(.ant5-tree-treenode-leaf-children-selected) {
    /* background-color: rgba(var(--toco-color-primary-value), 0.1); */
    background-color: var(--toco-color-bg-base);
  }

  :global(.ant5-tree-treenode-selected) {
    background-color: rgba(var(--toco-color-primary-value), 0.1);
    color: var(--toco-color-text-base);

    .label {
      color: var(--toco-color-text-base);
      font-weight: 500;
    }
  }

  /* :global(.ant5-tree-treenode-leaf-selected) {
    background-color: rgba(var(--toco-color-primary-value), 0.1);
  } */

  :global(.ant5-tree-node-selected) {
    background-color: transparent !important;
  }

  :global(.ant5-tree-treenode-leaf-hasIcon) {
    :global(.ant5-tree-iconEle) {
      opacity: 0;
    }
  }

  :global(.ant5-tree-switcher ) {
    display: none;
  }

  :global(.ant5-tree-iconEle) {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  :global(.ant-tree-list-scrollbar) {
    :global(.ant-tree-list-scrollbar-thumb) {
      width: 6px !important;
    }
  }
}


.toco-menu-header-collapsed {
  justify-content: center;

  .header-icon-group {
    right: unset;
  }
}

.toco-menu-footer {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toco-menu-collasped-block {
  padding-top: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.toco-menu-popover {
  .ant5-popover-inner-content {
    padding: 0 0;
  }

  .toco-menu-popover-container {
    max-height: 600px;
    overflow-y: auto;
    padding-left: 0;

    .toco-menu-item {
      .toco-menu-item-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .ant5-menu.ant5-menu-inline-collapsed {
    width: auto;
    min-width: 80px;

    >.ant5-menu-submenu>.ant5-menu-submenu-title {
      padding: 0 12px;
    }
  }
}

.toco-menu-popover-icon {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  flex: 0;

  >.icon {
    
  }
}

.toco-menu-lock-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  
}

.toco-menu-filter-container {
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d !important;
  border-right: none !important;

  .ant5-menu-item {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 34px !important;
    line-height: 34px !important;
  }
}

.toco-menu-self-module {
  flex: 1;
  height: calc(100% - 40px);

  .ant5-tabs-content-holder {
    display: none;
  }

  .ant5-tabs-tab {
    writing-mode: tb;
    padding: 8px 0 !important;
    transform: rotate(180deg);
    font-weight: bold;
  }
}

.toco-menu-title-dir-block {
  font-weight: bolder;
  white-space: nowrap;
  color: #5A5E69;
}

.toco-menu-title-module-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .menu-item-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .menu-item-right {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-left: 4px;
  }

  .label {
    white-space: nowrap;
    font-size: 14px;
    color: #313643;
    margin-left: 6px;
  }

  .collapse-icon {
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dir-label {
    font-weight: 400;
    font-size: 14px;
    color: #313643;
    margin-left: 6px;
  }

  .route-label {
    font-weight: 400;
    font-size: 12px;
    color: var(--toco-color-text-secondary);
    font-style: italic;
    margin-left: 0;
  }

  .root-label {
    font-weight: 500;
    color: var(--toco-color-text-base);
  }

  .middle-label {
    font-weight: 400;
    color: var(--toco-color-text);
  }

  .leaf-label {
    font-weight: 500;
    color: var(--toco-color-primary);
  }

  .editor-avatar {
    margin: 0 -2px !important;
  }

  .editor-list-avatar {
    margin-left: 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.toco-menu-title-module-block-active {
  .label {
    font-style: normal;
  }
}

.menu-data-filter {
  width: 100px;
  margin-right: 9px !important;

  :global(.ant5-select-selector) {
    background-color: var(--toco-color-bg-filter) !important;
    padding-left: 10px !important;
    height: 28px;
    border-color: var(--toco-color-primary) !important;
    border-radius: 2px;
  }

  :global(.ant5-select-selection-overflow-item) {
    :global(+.ant5-select-selection-overflow-item) {
      margin-left: 6px;
    }
  }
}

.menu-data-filter-complete {
  :global(.ant5-select-selector) {
    border-color: var(--toco-color-border-filter-complete) !important;
  }
}

.menu-data-filter-popup {
  padding: 8px !important;
  :global(.ant5-select-tree-switcher) {
    display: none;
  }
}

.menu-relation-filter {
  width: 100px;

  :global(.ant5-select-selector) {
    background-color: var(--toco-color-bg-filter) !important;
    padding-left: 10px !important;
    height: 28px !important;
    border-color: var(--toco-color-primary) !important;
    border-radius: 2px;
  }

  :global(.ant5-select-selection-overflow-item) {
    :global(+.ant5-select-selection-overflow-item) {
      margin-left: 6px;
    }
  }

  :global(.ant5-select-selection-item) {
    height: 26px !important;
  }

  .menu-relation-filter-label {
    color: var(--toco-color-text-base);
    margin-left: 7px;
  }
}

.menu-relation-filter-complete {
  :global(.ant5-select-selector) {
    border-color: var(--toco-color-border-filter-complete) !important;
  }
}

.menu-relation-filter-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .menu-relation-filter-item-label {
    margin-left: 7px;
  }
}

.tree-node-render {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .tree-node-label {
    margin-left: 7px;
  }
}

.toco-import-label {
  font-size: 14px;
  font-weight: 500;
  padding-right: 8px;

  &::after {
    content: ':';
    padding: 0 4px;
  }
}

.toco-import-module-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 24px;

  > div {
    width: 100%;
  }

  .toco-import-project {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .toco-import-extra-module {
    background-color: #f5f5f5;
    padding: 12px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .toco-import-label {
      font-weight: 400;
      font-size: 14px;
      white-space: nowrap;
    }

    :global(.ant5-tag)  {
      background-color: #fff;
      color: var(--toco-color-text-base);
    }
  }

  .toco-import-message {
    background-color: var(--toco-color-bg-card);
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--toco-color-text-secondary);
    max-height: 150px;
    overflow-y: auto;
  }
}

.toco-import-module-footer {
  margin: 0 -24px -24px -24px;
  height: 56px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--toco-color-border-secondary);
}