.dto-reverse-extend{
    font-size: 14px;
  
    :global(.ant5-table-cell) {
      font-size: 14px;
    }

    :global(.data-list >div:first-child) {
      margin-bottom: 0 !important;
    }
  }