.toco-icon-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: inherit;
    gap: 5px;
}

.clickable {
    
}