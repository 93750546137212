.button {
    background: transparent;
    border: none;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 4px;
    border-radius: 4px;
    color: var(--toco-color-text-base);
    .text {
        text-wrap: nowrap;
        flex-shrink: 0;
        font-size: var(--toco-font-size-sm);
        color: inherit;
        user-select: none;
    }
    .icon {
        width: 20px;
        height: 20px;
        color: inherit;
    }

    .loading {
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        will-change: transform;
        animation: spin 0.6s linear infinite;
    }

    &:focus-visible {
        outline: var(--toco-line-width-focus) solid var(--toco-color-primary-border);
        outline-offset: 1px;
        transition: outline-offset 0s, outline 0s;
    }
}

.buttonActive {
    background: var(--toco-color-primary);
    .text {
        color: var(--toco-color-btn-text);
    }
    .icon {
        color: var(--toco-color-btn-text);
    }
}

.buttonDisabled {
    cursor: url('../../../public/default-cursor.svg'), default;
    .text {
        color: var(--toco-color-text-disabled);
    }
    .icon {
        color: var(--toco-color-text-disabled);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}