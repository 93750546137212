.vs-cascade-modal {
    .cascade-steps {}

    .cascade-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 12px;
        border-radius: 2px;
        position: relative;
        max-height: calc(100vh - 294px);

        .cascade-sub-title {
            padding: 0 12px;
            height: 40px;
            font-size: 16px;
            line-height: 40px;
        }

        .cascade-sub-content {
            padding: 12px;
            flex: 1;
            max-height: calc(100vh - 336px);
            overflow: auto;
        }

        .nocheck {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .cascade-left {
            flex: 0;
            flex-basis: 30%;
            width: 30%;
            display: flex;
            flex-direction: column;
        }

        .cascade-right {
            flex: 1;
            width: 70%;
        }

        .vs-cascade-field-tree {
            .vs-tree-label {
                white-space: nowrap;
                font-style: italic;
                font-weight: 400;
            }

            .vs-tree-label-category {
                font-weight: bold;
                font-style: normal;
            }

            .vs-tree-field-label {
                text-decoration: line-through;
            }
        }

        :global(.ant5-checkbox-group) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.toco-cascade-modal {

}

.toco-cascade-mark {
    display: flex;
    gap: 6px;

    &::before {
        position: absolute;
        top: 50%;
        inset-inline-end: 0;
        width: 1px;
        height: 1.6em;
        background-color: rgb(230, 230, 230);
        transform: translateY(-50%);
        transition: background-color 0.2s;
        content: "";
    }
}

.toco-cascade-tips {
    position: absolute;
    left: 17px;
    bottom: 16px;
    height: 28px;
    width: 60%;
    display: flex;
    gap: 6px;

    .toco-cascade-tips-word {
        height: 28px;
        line-height: 28px;
    }
}

.data-tree-container {
    padding: 0 !important;
    overflow-y: hidden;
    overflow-x: auto;

    :global(.ant5-tree-list-holder-inner) {
        :global(.ant5-tree-treenode) {
            width: 100%;
            position: relative;
    
            &::after {
                content: '';
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: rgb(230, 230, 230);
                z-index: 9;
            }

            &:not(:first-of-type) {
                &::after {
                    bottom: -1px;
                    left: 0;
                    background-color: rgb(230, 230, 230);
                }
            }
            /* border-bottom: 1px solid rgb(230, 230, 230); */
        }
    }

    :global(.ant5-tree-switcher) {
        &:hover {
            background-color: unset !important;
        }
    }

    :global(.ant5-tree-node-content-wrapper) {
        &:hover {
            background-color: unset !important;
        }
    }

    :global(.ant5-tree-treenode-selected) {
        background-color: #EBF1FF;
    }

    :global(.ant5-tree-node-selected) {
        background-color: unset !important;
    }
}

.forbid-check-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .empty-content {
        height: 20px;
        width: 2px;
    }

    .word-content {
        position: fixed;
        margin-left: 80px;
    }
}

.toco-cascade-field-table {
    :global(.ant5-table-row .ant5-table-cell-row-hover) {
        background: #fff !important;
    }
    :global(.ant5-table-row-selected .ant5-table-cell-row-hover) {
        background: #EBF1FF !important;
    }
}

.toco-cascade-data-tree-container {
    position: relative;
    padding-bottom: 10px;
    overflow: hidden;
    overflow-x: auto;
}