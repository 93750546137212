.toco-upload-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .ant-upload-drag {
    border-width: 0;
  }
}

.preview-image {
  opacity: 0;
}
