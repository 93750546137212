.userinfo-popover {
    font-size: 16px;
    color: var(--toco-color-text-base);
    width: 150px;
}

.userinfo-popover-wrap {
    :global(.ant5-popover-inner) {
        padding: 0;
    }
}

.user-nickname {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.list-item {
    height: 40px;
    padding: 0 18px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    font-size: 14px;

    &:hover {
        
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.base {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    gap: 12px;
    background: linear-gradient(180deg, rgba(19, 97, 179, 0.1) 0%, rgba(208, 202, 249, 0.1) 100%);

    :global(> span) {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }
}
