.color-select {
  display: flex;
  align-items: center;
  gap: 8px;
  
  .color-option {
      width: 20px;
      height: 20px;
      color: #fff;
      font-weight: 700;
      text-align: center;
      border-radius: 2px;
      
  }
}
