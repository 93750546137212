.tab-tag {
  color: var(--toco-color-btn-text);
  background-color: var(--toco-color-primary);
  font-size: 9px;
  text-align: center;
  border-radius: 2px;
  padding: 3px 4px;
  margin-left: 10px;
  height: 18px;
  line-height: 1;
  white-space: nowrap;
}