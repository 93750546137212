.toco-dictionary-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.toco-naming {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}

.toco-naming-table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;

    .tag-filter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    .filter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .oper {
        margin-left: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
    }

    .word {
        position: relative;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: var(--toco-color-text-base);

        &::before {
            content: "";
            background-color: var(--toco-color-primary);
            width: 4px;
            height: 80%;
            position: absolute;
            left: 0;
            top: 10%;
        }
    }
}

.toco-naming-table-tag-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    overflow: auto;
    padding: 12px 24px;
    height: 100%;
    border-right: 1px solid var(--toco-color-border-secondary);

    :global(.ant5-tree-switcher-noop) {
        display: none;
    }

    :global(.ant5-tree) {
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    :global(.ant5-tree-switcher) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.toco-naming-table-naming-container {
    padding: 12px 24px;
    height: 100%;
    width: 1000px;
    max-width: 1000px;
    min-width: 1000px;

    :global(.ant5-table-tbody-virtual-scrollbar-horizontal) {
        display: none;
    }

    /* :global(.ant5-spin-container) {
        :global(td.ant5-table-cell) {
            width: 100%;
            display: block;
        }
    } */
}

.tag-filter-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tag-title {
    margin-right: 14px;
    font-size: 14px;
    color: var(--toco-color-text-base);
}

.toco-dictionary-tabs {
    height: 100%;

    :global(.ant5-tabs-tab) {
        height: 48px;
        font-size: 14px;
        margin: 0;
        margin-left: 24px;
    }
    :global(.ant5-tabs-tab-active) {
        color: var(--toco-color-primary);
    }
    :global(.ant5-tabs-ink-bar) {
        background-color: var(--toco-color-primary);
    }
    :global(.ant5-tabs-nav) {
        margin: 0;
    }
    :global(.ant5-tabs-content) {
        height: 100%;
    }
    :global(.ant5-tabs-tabpane) {
        height: 100%;
    }
    :global(.ant5-tabs-tab-disabled) {
        color: var(--toco-color-text-base) !important;
        cursor: url('../../../../public/default-cursor.svg'), default !important;
        font-size: 18px !important;
    }
}

.tag-table-operation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.tag-table-operation-edit {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > * {
        white-space: nowrap;
    }
}

.toco-dictionary-classify {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 100%;
    user-select: none;

    .classify-item-add {
        width: 268px;
        height: 214px;
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
        border: 1px solid var(--toco-color-border-secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        

        .classify-item-add-word {
            font-size: 14px;
            color: var(--toco-color-text-secondary);
            margin-top: 12px;
        }
    }

    .classify-item {
        width: 268px;
        height: 214px;
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
        border: 1px solid var(--toco-color-border-secondary);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .classify-item-title {
            width: 100%;
            border-top: 4px solid var(--toco-color-primary);
            border-radius: 6px 6px 0 0;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            border-bottom: 1px solid var(--toco-color-border-secondary);

            .classify-item-title-word {
                font-size: 16px;
                color: var(--toco-color-primary);
                font-weight: 600;
                border-bottom: 1px solid var(--toco-color-text-tertiary) !important;
            }

            .classify-item-title-operation {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                
                color: var(--toco-color-primary);
            }
        }

        .classify-item-container {
            width: 100%;
            flex: 1;
            overflow-y: auto;

            .classify-item-row {
                height: var(--toco-control-default-height);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 16px;
                padding-right: 10px;
            }

            .classify-item-row-close {
                ;
            }
        }

        .classify-item-append {
            width: 100%;
            flex: 0;
            height: 38px;
            min-height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            color: var(--toco-color-primary);
            border-top: 1px solid var(--toco-color-border-secondary);
        }
    }

    .operation-word {
        font-size: 14px;
        margin-left: 4px;
    }

    .classify-item-row-line {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid var(--toco-color-text-tertiary) !important;
    }

    .classify-item-row-value {
        @mixin input-with-underline var(--toco-color-text-base);
        padding-left: 0;
    }
}

.dictionary-add-modal-container {
    display: flex;
    padding: 15px 0 0 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > * {
        width: 100%;
    }
}

.dictionary-add-modal-btn {
    height: 56px;
    max-height: 56px;
    min-height: 56px;
    flex: 0;
    padding: 0 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid var(--toco-color-border-secondary);

    :global(.ant5-btn) {
        margin-left: 12px;
    }
}

.dictionary-add-modal {
    :global(.ant5-modal-title) {
        height: 48px;
        line-height: 48px;
        padding: 0 24px;
        color: var(--toco-color-primary);
        border-bottom: 1px solid var(--toco-color-border-secondary);
    }

    :global(.ant5-modal-content) {
        padding: 0;
    }
}

.dictionary-name-input {
    :global(.name-input) {
        border: 1px solid var(--toco-color-border-secondary);
        border-radius: 2px;
        padding: 0 7px;
    }

    :global(.ant5-form-item-label) {
        position: relative;
        top: 2px;
    }
}

.form-container {
    padding: 18px 0 32px 0;
    :global(.ant5-form-item-required) {
        &::before {
            content: "" !important;
        }
    }

    :global(.desc-input) {
        height: var(--toco-control-default-height);
    }

    :global(.ant5-select-selector) {
        height: var(--toco-control-default-height);
    }

    .translate-form-item {
        margin-bottom: 0;
        margin-top: -16px;

        :global(.ant5-form-item-label > label) {
            &::after {
                content: ''
            }
        }
    }

    .translate-form-item-word {
        display: flex;

        .translate-form-item-word-oper-label {
            margin-left: 4px;
        }

        .translate-form-item-word-oper {
            display: flex;
            gap: 2px;
            margin-left: 4px;
        }
    }

    .translate-form-item-word-active {
        .translate-form-item-word-oper-label {
            color: var(--toco-color-primary);
        }

        .translate-form-item-word-logo {
            color: var(--toco-color-primary);
        }
    }
}

.extra-info-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.extra-info-label {
    margin-right: 12px;
    position: relative;
    white-space: nowrap;

    &::after {
        content: ':';
        position: absolute;
        right: -6px;
    }
}

.extra-info-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
}

.similar-info-container {
    color: rgba(255, 149, 0, 1);
}

.conflict-info-container {
    color: rgba(219, 67, 63, 1);
}

.cursor-info-container {
    .extra-info-item {
        
        text-decoration: underline;    
    }
}

.dictionary-highlight-word {
    > b {
        color: var(--toco-color-primary);
    }
}