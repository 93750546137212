.root {
  .list {
    margin-bottom: 40px;

    :global(.ant5-table-wrapper) {
      height: unset !important;
    }
  
    :global(.ant5-table-body) {
      max-height: unset !important;
    }
  }
}