.error {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  color: var(--toco-color-text-base);

  span {
    
    color: var(--toco-color-primary);
  }
}

.newFile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
  margin-bottom: 4px;
}

.root {

  .form {
    :global(.ant5-form-item) {
      margin-bottom: 24px;
      color: var(--toco-color-text-base);
    }
    
    :global(.ant5-form-item-label) {
      width: 80px;
      
      label {
        font-size: 14px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 超出部分用省略号表示 */
      }
  
      label:not(:global(.ant5-form-item-required)) {
        margin-left: 10px;
      }

      >label{
        color: var(--toco-color-text-base);
      }
    }

    :global(.ant5-form-item-control-input-content) {
      font-size: 14px;
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 超出部分隐藏 */
      text-overflow: ellipsis; /* 超出部分用省略号表示 */


      :global(.ant5-input-affix-wrapper) {
        @mixin input-with-underline var(--toco-color-field-cname);
      }

      .cname {
        @mixin input-with-underline var(--toco-color-field-cname);
      }

      :global(.ant5-select-selector) {
        @mixin input-with-underline var(--toco-color-text-base);
      }

      :global(.name-input) {
        @mixin input-with-underline var(--toco-color-text-base);
        margin-left: 6px;
      }

      :global(.ant5-input-outlined) {
        @mixin input-with-underline var(--toco-color-text-base);
      }
    }

    :global(.ant5-form-item-explain) {
      font-size: 14px;
    }

    :global(.ant5-select-selector) {
      font-size: 14px;
    }

    input {
      font-size: 14px;
    }

    .bodyItem {
      :global(.ant5-row) {
        flex-direction: column;
      }

    
      :global(.ant5-form-item-label) {
        width: 300px;

        :global( >label::after) {
          content:none;
        }
      }
  

      :global(.ant5-form-item-control) {
        min-height: unset;
      }

      .rpc-param-title {
        font-weight: 500;
        font-size: 15px;
        color: var(--toco-color-text-base);
      }

      .rpc-param-info {
        padding-left: 6px;
        font-weight: 400;
        font-size: 14px;
        color: var(--toco-color-text-secondary);
      }

      .body {
        margin-top: 10px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .field-type {
          color: var(--toco-color-field-type);
        }

        .funcNameRoot {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          height: 32px;

          input {
            font-size: 14px;
            text-align: center;
          }

          :global(.name-input) {
            width: unset !important;
            margin-left: 5px;
            padding-bottom: 2px;;
          }

          .return-type {
            margin-right: 8px;
          }

          .func-name {
            margin-left: 6px;
            color: var(--toco-color-text-base);
          }

          .bracket {
            margin-left: 8px;
          }
        }

        .funcParams {
          .add {
            min-height: 30px;
            max-height: 30px;
            display: flex;
          }

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          line-height: 32px;
          margin: 0px 16px;

          :global(.toco-icon-container) {
            
          }

          .param {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:hover {
              outline-style: solid;
              outline-width: 1px;
              outline-color: var(--toco-color-text-tertiary);
              outline-offset: 1px;
              background: var(--toco-color-bg-hover);
            }

            .param-name{
              padding-left: 6px;
              line-height: 32px;
              color: var(--toco-color-field-name);
            }

            .param-description{
              padding-left: 6px;
              line-height: 32px;
              color: var(--toco-color-text-secondary);
            }
  
            .param-type{
              color: var(--toco-color-field-type);
            }

            .param-left{
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }

            .more-buttons {
              /* margin-left: auto; */
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap:16px;
            }

            .operation {
              margin-left: 6px;
              width: 80px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 22px;
            }
          }
        }

        .end {
          margin-left: 10px;
        }

        :global(.ant5-select-selector) {
          color: var(--toco-color-field-type) !important;
          padding-top: 0px;
          padding-bottom: 0px;
          border-color: var(--toco-color-field-type) !important;
        }

        :global(.name-input) {
          height: 32px;
          width: auto !important;
        }
      }
    }
  }

  .header {
    &::before {
      content: '';
      width: 4px;
      height: 16px;
      margin-right: 6px;
      background: var(--toco-color-primary);
    }
    font-size: 18px;
    font-weight: 500;
    color: var(--toco-color-text-base);
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  .buttons {
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;

    .button {
      display: flex;
      align-items: center;
      padding: 5px;
      font-size: 14px;
    }

    .edit {
      svg {
        color: var(--toco-color-text-secondary);
      }
    }
  }
}