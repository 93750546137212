.vs-settings-container {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    justify-content: center;

    &:hover {
        box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.1);
        /* background-color: var(--toco-color-bg-mouse-hover); */
        color: var(--toco-color-primary);
    }
}

.vs-settings {
    .settings-item {
        margin-bottom: 12px;
    }

    .title {
        margin-bottom: 12px;
        color: var(--toco-color-text-base);
    }

    .version {
        color: var(--toco-color-text-base);
    }

    .content {
        display: flex;
        color: var(--toco-color-text-base);
        
        >div {
            margin-right: 16px;
        }
    }
}


.vs-theme-block {
    width: 44px;
    height: 36px;
    background-color: #F0F2F5;
    border-radius: 4px;
    box-shadow: 0 1px 2.5px 0 rgb(0 0 0 / 18%);
    position: relative;
    

    .anticon {
        color: var(--toco-color-text-base);
        position: absolute;
        right: 6px;
        bottom: 6px;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 33%;
        height: 100%;
        background-color: #fff;
        content: "";
        border-radius: 4px;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background-color: #fff;
        content: "";
        border-radius: 4px;
    }
}

.vs-theme-dark-block {
    background-color: rgba(0, 21, 41, .85);

    &::before {
        background-color: rgba(0, 21, 41, .65)
    }

    &::after {
        background-color: rgba(0, 21, 41, .65)
    }
}

.vs-layout-setting {
    .vs-layout-item {
        width: 44px;
        height: 36px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 2.5px 0 rgb(0 0 0 / 18%);
        position: relative;
        
        display: flex;
        justify-content: flex-end;
        float: left;
        margin-right: 16px;
        color: #fff;

        >div {
            height: 50%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .vs-layout-item-footer {
        flex-direction: column;

        >div {
            border-radius: 0 0 4px 4px;
        }
    }

    .vs-layout-item-right {
        flex-direction: row;

        >div {
            width: 50%;
            height: 100%;
            border-radius: 0 4px 4px 0;
        }
    }
}