.wrapper {
    width: fit-content;
}

.wrapperScroll {
    width: 100%;
    overflow-x: auto;
}

.container {
    width: fit-content;
    overflow: hidden;

    .rowIndex::before {

    }

    .tree {
        background: transparent;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 10px;

        :global(.filter-node) b {
            background-color: #00AAFF;
            color: white;
            font-weight: normal;
        }

        :global(.filter-node .ant5-tree-title) {
            font-weight: normal !important;
        }

        :global(.ant5-tree-treenode) {
            padding-bottom: 0;
        }

        :global(.ant5-tree-node-content-wrapper) {
            flex: 1;
            padding: 0 !important;
        }

        :global(.ant5-tree-switcher-leaf-line) {
            &::after {
                display: none;
            }
        }
        &:global(.ant5-tree-focused) {
            outline-offset: -2px !important;
        }

        :global(.ant5-tree-list-holder-inner) {
            position: relative !important; /* 修复虚拟滚动tree无法显示的问题 */
        }

        :global(.ant5-tree-draggable-icon) {
            display: none;
        }

        &.treeDrag {
            :global(.ant5-tree-draggable-icon) {
                display: block;
                opacity: 1;
            }

            :global(.ant5-tree-iconEle) {
                left: -128px;
            }

            :global(.ant5-tree-indent-unit::before) {
                display: none;
            }
        }
    }

    .row {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        .left {
            overflow: hidden;
            display: flex;
            justify-content: start;
            align-items: center;
            padding-right: 16px;
        }

        .right {
            height: 32px;
        }

        .info {
            display: flex;
            gap: 6px;
            align-items: center;
        }

        .fixed {
            display: flex;
            gap: 4px;
            align-items: center;
            flex-shrink: 0;
        }

        .text {
            height: 32px;
            display: flex;
            gap: 6px;
            justify-content: start;
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .error {
            text-decoration: var(--toco-color-error) wavy underline;
            text-underline-offset: 4px;
            & * {
                text-decoration: var(--toco-color-error) wavy underline;
                text-underline-offset: 4px;
            }
        }

        .brace {
            color: var(--toco-color-text-base)
        }
        .input {
            height: 28px !important;
            border-bottom: 1px solid transparent;
            :global(.enhanced-input), :global(.ant5-select-selection-item > span) {
                height: 28px !important;
                line-height: 28px !important;
            }
        }
        .type {
            :global(.ant5-select-selection-item > span) {
                height: 28px !important;
                line-height: 29px !important;
            }
            font-size: inherit;
            color: var(--toco-color-field-type);
            font-family: var(--toco-font-family-code);
            border-bottom: 1px solid transparent;

            :global(.ant5-select-selector) {
                
                padding: 0;
            }

            &.input {
                border-color: var(--toco-color-field-type);
            }
        }
        .nameEdit {
            display: flex;
        }
        .name {
            height: 28px !important;
            line-height: 28px !important;
            color: var(--toco-color-field-name);
            font-family: var(--toco-font-family-code);
            &.input {
                border-color: var(--toco-color-field-name);
            }
        }
        .cname {
            display: block;
            height: 28px !important;
            line-height: 28px !important;
            color: var(--toco-color-field-cname);
            font-family: var(--toco-font-family-code);
            &.input {
                border-color: var(--toco-color-field-cname);
            }
        }
        .moreInfo {
            height: 32px;
            display: flex;
            gap: 2px;
            justify-content: center;
            align-items: center;

            .btn {
                color: var(--toco-color-text-secondary);
            }
        }

        .errors {
            flex-shrink: 0;
            display: flex;
            gap: 4px;
            padding-right: 6px;
            .errorTip {
                display: flex;
                align-items: center;
                height: 24px;
                padding: 6px 12px;
                border-radius: 3px;
                font-size: 12px;
                color: #DB433F;
                background-color: #FBECEC;
                gap: 6px;

                .icon {
                    font-size: 13px;
                }
            }
        }

        .buttons {
            display: flex;
            gap: 10px;

            .btn {
                padding: 0;
                color: var(--toco-color-primary);
            }
        }

        &.rowRoot, &.rowRoot :global(.enhanced-input) {
            font-size: 16px;
        }

        &.rowHide {
            .type {
                color: var(--toco-color-text-tertiary) !important;
            }
            .brace, .name, .cname {
                color: var(--toco-color-text-tertiary);
            }
            
            :global(.toco-type-select-preview) {
                color: var(--toco-color-text-tertiary) !important;
            }
        }
    }

    :global(.ant5-tree-treenode) {
        padding-left: 34px;
    }

    :global(.ant5-tree-node-content-wrapper:hover) {
        background: none;
    }

    :global(.ant5-tree-iconEle) {
        position: absolute;
        left: -96px;
    }
}

.sourceTip {
    :global(.ant5-popover-inner) {
        background: var(--toco-color-bg-tooltip);
        border: 1px solid var(--toco-color-tooltip-border);
        height: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;

        :global(.ant5-popover-inner-content) {
            font-size: 12px;
            height: 12px;
            line-height: 12px;
        }
    }
}

.noIndex {
    :global(.ant5-tree-treenode) {
        padding-left: 0;
    }

    .header .left {
        padding-left: 0;
        border-left: none;
    }
}

.header {
    width: fit-content;
    display: flex;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    margin-right: 2px;
    font-weight: 500;
    color: var(--toco-color-text);
    background-color: var(--toco-table-header-bg);
    overflow: hidden;

    .left {
        display: flex;
        align-items: center;
        padding-left: 32px;
        padding-right: 16px;
        gap: 20px;
        height: 32px;
        border-top: 1px solid var(--toco-color-border-secondary);
        border-bottom: 1px solid var(--toco-color-border-secondary);
        border-left: 1px solid var(--toco-color-border-secondary);

        .title {
            padding-left: 8px;
            border-left: 1px solid var(--toco-color-border-secondary);
            white-space: nowrap;
        }

        .extraAction {
            display: flex;
            align-items: center;
        }
    }

    .right {
        border-top: 1px solid var(--toco-color-border-secondary);
        border-bottom: 1px solid var(--toco-color-border-secondary);
        border-right: 1px solid var(--toco-color-border-secondary);
    }

    .tableCol {
        height: 32px;
        border-left: 1px solid var(--toco-color-border-secondary);
    }
}

.tableRow {
    display: flex;
    height: 100%;

    .tableCol {
        white-space: nowrap;
        padding: 0 16px;
        font-size: 14px;
        overflow: hidden;

        > * {
            max-width: 100%;
            display: flex;
            align-items: center;
        }
    }
}

.description {
    width: 100%;
}

.colorPrimary {
    color: var(--toco-color-primary) !important;
}

.dragIcon {
    width: 32px;
    height: 32px;
    color: var(--toco-color-text-secondary);
}

.dragButton {
    &.dragButtonEnabled {
        border-color: var(--toco-color-border) !important;
    }
    .dragButtonContent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: var(--toco-color-text);

        .btn {
            height: 20px;
            padding: 0;
            border: 0;
            font-size: 12px;
            color: var(--toco-color-text-secondary);
        }
        .save, .save:hover {
            color: var(--toco-color-primary) !important;
        }
    }
}

