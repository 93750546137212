.root {
  padding: 12px;
  height: 100%;

  .enum-table {
    height: 100%;

    .extra {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-right: 8px;

      :global(.ant5-btn-sm) {
        height: 26px;
      }
    }

    .btn {
      color: var(--toco-color-primary);
    }
  }
}