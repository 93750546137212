.enhanced-input-container ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.enhanced-input-container {
  padding-top: unset !important;
  padding-bottom: unset !important;
  cursor: text !important;
  display: flex !important;
  transition: none !important;
  height: 100%;
  box-sizing: border-box;
  padding: 3px 0;
}

.enhanced-input {
  white-space: nowrap;
  overflow: auto;
  scrollbar-width: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  height: var(--toco-control-default-height);
  line-height: var(--toco-control-default-height);
  /* display: flex;
  align-items: center; */
}

.enhanced-input-sm {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 26px;
} 

.enhanced-input-lg {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  min-height: 42px;
} 

.enhanced-input br {
  display: none;
}

.enhanced-input * {
  display: inline;
  white-space: nowrap;
}

.enhanced-input .marker {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-underline-offset: 4px;
}

.enhanced-input .ctrl-hover {
  
}

.enhanced-input .error {
  text-decoration-color: red;
}

.enhanced-input .warning {
  text-decoration-color: orange;
}

.enhanced-input .info {
  text-decoration-color: blue;
}

.enhanced-input:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  color: var(--toco-color-text-placeholder);
  text-overflow: ellipsis;
  overflow: hidden;
}
