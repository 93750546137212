.toco-confirm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .toco-confirm-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        :global(.anticon) {
            color: #faad14;
        }
    }

    .toco-confirm-title-label {
        margin-left: 4px;
        font-weight: 600;
        /* color: rgba(0, 0, 0, 0.88); */
    }

    .toco-confirm-desc {
        margin-top: 4px;
        /* color: rgba(0, 0, 0, 0.88); */
    }
}