.toco-global-search-modal {
    :global(.ant5-modal-content) {
        padding: 0 !important;
        border-radius: 12px;
    }

    :global(.ant5-modal-body) {
        border-radius: 12px;
        max-height: 9999px !important;
    }

    :global(.ant5-input-outlined:focus-within) {
        border-color: var(--toco-color-search-border);
        box-shadow: unset;
    }

    :global(.ant5-input-outlined) {
        border-color: var(--toco-color-search-border);
    }

    .toco-global-search {
        background-color: var(--toco-color-bg-search);

        .search-icon {
            position: absolute;
            top: 15px;
            left: 12px;
            z-index: 9;
        }

        :global(.ant5-select) {
            width: 100%;
            height: 52px;

            :global(.ant5-select-selector) {
                font-size: 20px;
                padding-left: 40px;
            }

            :global(.ant5-select-clear) {
                width: 24px;
                height: 24px;
                top: calc(50% - 8px);
                opacity: 1;
            }

            :global(.ant5-select-selection-search-input) {
                color: var(--toco-color-text-base) !important;
                padding-left: 32px;
            }

            &:hover {
                :global(.ant5-select-selector) {
                    border-color: var(--toco-color-border-secondary) !important;
                }
            }
        }
        :global(.ant5-select-focused > .ant5-select-selector) {
            border-color: var(--toco-color-border-secondary) !important;
            box-shadow: none !important;
        }
        :global(.ant5-select-selector) {
            border: none;
            background: var(--toco-color-bg-search);
            border-bottom: 1px solid var(--toco-color-border-secondary);
        }
    }

    .toco-global-search-filter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 12000;
        height: 50px;
        min-height: 50px;
        max-height: 50px;
        padding: 0 16px;
    }

    .toco-global-search-filter-item {
        height: 26px;
        padding: 0 12px;
        line-height: 26px;
        border-radius: 26px;
        text-align: center;
        border-right: 1px solid var(--toco-color-border-secondary);
        
        border-color: transparent;
    }

    .toco-global-search-filter-item-active {
        color: #fff;
        background-color: var(--toco-color-primary);
    }
}

.toco-global-search-container {
    box-shadow: none;
    padding: 44px 12px 12px 12px;
    position: relative;
    width: 680px !important;

    :global(>div) {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    :global(.rc-virtual-list) {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    :global(.rc-virtual-list-holder) {
        min-width: 100%;
        max-width: 100%;
        width: 100%;

        :global(>div) {
            min-width: 100%;
            max-width: 100%;
            width: 100%;
        }
    }

    :global(.rc-virtual-list-holder-inner) {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    :global(.ant5-select-item-option-active) {
        background-color: rgba(var(--toco-color-primary-value), 0.1) !important;
        
    }
}

.toco-global-search-item {
    height: 46px;
    border-radius: 4px;
    /* padding: 0 8px; */
    display: flex;
    justify-content: space-between;
    align-items: center;

    :global(b) {
        color: var(--toco-color-primary);
    }

    .module-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .module-item-div {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        :global(:not(:last-of-type)) {
            margin-right: 6px;
        }
    }

    .left {
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .word-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 10px;
        }

        .word {
            font-size: 14px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
            .word-extra {
                color: var(--toco-color-primary);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
            }
        }

        .word-info-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            margin-left: 4px;
        }

        .desc {
            font-size: 12px;
            color: var(--toco-color-text-tertiary);
        }
    }

    .right {
        font-weight: 400;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .word {
            margin-right: 6px;
        }
    }
}

.toco-global-search-content {
    min-height: 540px;
    max-height: 540px;
    background-color: var(--toco-color-bg-search);
    position: relative;
    overflow: hidden;
    padding-top: 50px;
    width: 100%;
}