.root {

  :global(.ant5-drawer-body) {
    padding: 0;
  }

  :global(.ant5-drawer-header) {
    padding-block: 0;
  }
  :global(.ant5-drawer-header-title) {
    height: 48px;
  }
  :global(.ant5-drawer-close) {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
  }
  :global(.ant5-drawer-title) {
    overflow-x: hidden;
  }

  .header {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 80px;

    .text {
      flex-shrink: 0;
      font-weight: 500;
      font-size: 16px;
      color: var(--toco-color-text-base);
    }

    .tabs {
      flex: 1;
      padding-right: 20px;
      overflow: hidden;
      height: 48px;

      :global(.ant5-tabs-nav::before) {
        display: none;
      }
      :global(.ant5-tabs-nav) {
        margin-bottom: 0;
      }
      :global(.ant5-tabs-tab) {
        padding-block: 12px;
        font-size: 14px;
        font-weight: 400;
        color: var(--toco-color-text-base);
      }

      :global(.ant5-tabs-tab+.ant5-tabs-tab) {
        margin-left: 24px;
      }

      :global(.ant5-tabs-tab-active .ant5-tabs-tab-btn) {
        *[data-type=h2] {
          color: var(--toco-color-primary);
        }
      }

      *[data-type=h2] {
        position: relative;
        font-size: 12px;
        color: var(--toco-color-text-secondary);

        &::before {
          position: absolute;
          left: -12px;
          top: 5px;
          display: block;
          content: '';
          width: 1px;
          height: 12px;
          background: var(--toco-color-border-secondary);
        }
      }
      *[data-firstH2=true] {
        margin-left: -12px;

        &::before {
          display: none;
        }
      }
    }
  }
}

.withPadding {
  :global(.ant5-drawer-body) {
    padding-top: 24px;
  }
}

.extra {
  display: flex; 
  justify-content: end;
  align-items: center;
  height: 100%;

  .buttons {
    display: flex;
    gap: 12px;
    :global(.ant5-btn) {
      font-size: 14px;
      height: 32px;
      min-width: 68px;
    }
  }
}