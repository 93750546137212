.container {
    position: relative;
    height: 100%;
    overflow-y: auto;
    background: var(--toco-color-bg-base);

    h1 {
        @mixin head-h1 100px;
        background: var(--toco-color-bg-base);
        position: sticky;
        top: 49px;
        z-index: 99;
        margin-inline: -24px;
        padding-left: 34px;
        padding-right: 24px;

        &::before {
            left: 24px;
        }
    }

    h2 {
        @mixin head-h2 111px;
    }
}

.container-no-tabs {
    h1 {
        scroll-margin: 0;
        top: 0;
    }
    h2 {
        scroll-margin: 64px;
    }
}

.container-no-scroll {
    overflow-y: unset;
}

.tab {
    height: 49px;
    position: sticky;
    top: 0;
    z-index: 1;
    overflow: hidden;
    background: var(--toco-color-bg-base);

    :global(.ant5-tabs-nav-list) {
        padding-left: 24px;
    }

    :global(.ant5-tabs-nav) {
        margin-bottom: 0;
    }

    :global(.ant5-tabs-nav) {
        margin-bottom: 0;
    }

    :global(.ant5-tabs-tab) {
        padding-block: 12px;
        font-size: 14px;
        font-weight: 400;
        color: var(--toco-color-text-base);
    }

    :global(.ant5-tabs-tab+.ant5-tabs-tab) {
        margin-left: 24px;
    }

    :global(.ant5-tabs-tab-active .ant5-tabs-tab-btn) {
        .subTab {
            color: var(--toco-color-primary);
        }
    }

    :global(.ant5-tabs-extra-content) {
        padding-right: 24px;
    }

    .subTab {
        position: relative;
        font-size: 12px;
        color: var(--toco-color-text-secondary);

        &::before {
            position: absolute;
            left: -12px;
            top: 5px;
            display: block;
            content: '';
            width: 1px;
            height: 12px;
            background: var(--toco-color-border-secondary);
        }

        &.subTabFirst {
            margin-left: -12px;

            &::before {
                display: none;
            }
        }
    }
}

.content {
    padding: 0 24px 24px 24px;
}

.content-no-padding {
    padding: 0;
}

.sticky {
    box-shadow: 0 2px 8px -2px rgba(0,0,0,.15);
}

.no-sticky {
    position: relative !important;
}