.root {
  .list {

    .header {  
      font-size: 14px;

      .text {
        color: var(--toco-color-text-base);
      }

      :global(.ant5-select-selection-item) {
        font-size: 14px;
      }
    }

    :global(.ant5-table-cell) {
      font-size: 14px;
      
    }
  }
}