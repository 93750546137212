.login {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  -webkit-app-region: drag;

  .logo {
    width: 500px;
    margin-bottom: 10px;
  }

  .inputs {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-app-region: no-drag;
  }
  .buttons {
    -webkit-app-region: no-drag;
  }
}