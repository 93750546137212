.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.extra-column-container {
    :global(.ant5-select) {
        max-width: 100%;
        width: 100%;
        @mixin select-with-underline var(--toco-color-input-bottom-border);
    }

    :global(.ant5-select-selection-item) {
        color: var(--toco-color-text-base) !important;
    }
}

.extra-column-center-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bo-choose-root {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--toco-color-text-secondary);

    :global(.ant5-btn-default) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bo-choose-root-operation {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        :global(.ant5-select-selector) {
            transition: none;
            color: inherit;
            border: none !important;
            border-width: 0 !important;
            box-shadow: none !important;
            background: transparent !important;
            border-radius: 0;
            border-bottom: 1px solid var(--toco-color-field-cname) !important;
            font-size: inherit !important;
            padding: 2px 0;
            display: flex;
            align-items: center;
        }

        :global(.ant5-select-selection-item) {
            padding-inline-end: 22px;
            font-size: 14px;
            color: var(--toco-color-text-base);
        }
    }

    .root-oper-btn {
        margin-right: 12px;
    }

    .bo-choose-root-label {
        font-size: 16px;
        color: var(--toco-color-text-base);
        margin-right: 14px;
        position: relative;

        &::after {
            content: ':';
            position: absolute;
            right: -6px;
        }
    }

    :global(.ant5-select) {
        min-width: 150px;
        margin-right: 12px;
    }

    :global(.toco-icon-container) {
        
    }
}

.bo-choose-root-select {
    padding: 0;

    :global(.ant5-select-tree-switcher) {
        display: none;
    }

    :global(.ant5-select-tree-list-holder) {
        > div {
            padding: 4px;
        }
    }
}

.add-btn {
    /* width: 100%; */
    margin-top: 12px;
    margin-bottom: 12px;

    :global(.ant5-btn) {
        /* width: 100%; */
    }
}

.bto-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > div {
        width: 100%;
    }

    h2{
        margin-bottom: 0;
    }
}

.bto-content {
    width: 100%;
    /* flex: 1; */
    /* padding: 24px; */
    overflow-y: auto;
}

.bto-footer {
    width: 100%;
    height: 58px;
    min-height: 58px;
    flex: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px;
    border-top: 1px solid var(--toco-color-border-secondary);
}

.bto-form-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    font-size: 14px;
}

.bto-form-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 36px;

    .label {
        margin-right: 14px;
        position: relative;
        font-size: 14px;
        font-weight: 500;
        color: var(--toco-color-text-base);

        &::after {
            content: ':';
            position: absolute;
            right: -6px;
        }
    }
    
    .value {
        min-width: 160px;
        color: var(--toco-color-text-base);
        font-size: 14px;
        /* border-bottom: 1px solid #000; */

        > div {
            border-bottom: 1px solid var(--toco-color-field-cname) !important;
        }

        :global(.bto-desc-input) {
            border: none;
            border-radius: 0;
            box-shadow: none !important;
            width: 160px;
            background-color: transparent;

            &:hover {
                border-color: transparent;
            }
        }
    }

    :global(.ant5-checkbox-wrapper) {
        font-size: 14px;
        color: var(--toco-color-text-base);
    }
}

.bto-setting-popover {
    :global(.ant5-popover-inner) {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    :global(.ant5-popover-inner-content) {
        overflow-y: auto;
        flex: 1;
        width: 100%;
    }

    :global(.ant5-checkbox-group ) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    :global(.ant5-checkbox-group-item) {
        width: 100%;
    }
}

.bto-action-render-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > span {
        margin-right: 4px;
        flex: 1;
        position: relative;
    }

    .falsy {
        text-align: center;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #000;
            left: 0;
            top: 50%;
        }
    }

    :global(.toco-icon-container) {
        color: var(--toco-color-primary);
    }
}