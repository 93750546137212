.object-viewer {
    background-color: var(--toco-color-bg-tooltip);
    border: 1px dashed var(--toco-color-tooltip-border);
}

.toco-object-viewer {
    background-color: var(--toco-color-bg-tooltip);
    border: 1px dashed var(--toco-color-tooltip-border);
    box-shadow: 0px 2px 16px 0px #00000029;
    min-height: 160px;
    min-width: 320px;
    display: flex;

    :global(.BizObjectGenerator-header-left) {
        background-color: var(--toco-color-bg-tooltip) !important;
        padding-left: 16px !important;
        gap: 12px;

        > span {
            border-left-width: 0;
            padding-left: 0;
        }
    }

    :global(.BizObjectGenerator-header-right) {
        background-color: var(--toco-color-bg-tooltip) !important;
        border-right-width: 0;

        > div {
            display: none;
        }
    }
}

.container {
    min-width: 200px;
    flex-grow: 1;
    display: flex;
    justify-content: center;

    .close {
        position: absolute;
        top: 9px;
        right: 9px;
        color: var(--toco-color-text-secondary);
    }

    .refresh {
        position: absolute;
        top: 9px;
        right: 35px;
        color: var(--toco-color-text-secondary);
    }

}