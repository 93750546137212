.toco-image-editor {
    :global(.tui-image-editor-header-logo) {
        display: none;
    }
    :global(.tui-image-editor-download-btn) {
        
    }
}

.toco-image-editor-operation {
    position: absolute;
    color: #fff;
    z-index: 9;
    top: 8px;
    right: 256px;
    display: inline-block;
    width: 120px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    outline: none;
    border-radius: 20px;
    border: 1px solid #ddd;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    vertical-align: middle;
    letter-spacing: .3px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #222;
    font-size: 12px;
}

.toco-image-editor-container {
    width: 100%;
    height: 100%;

    :global(.tui-image-editor-header) {
        display: none;
    }

    :global(.tui-image-editor-help-menu) {
        display: none;
    }

    .toco-image-editor-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 9;
    }
}