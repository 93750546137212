.root {
  .list {
    padding-left: 9px;
  }

  .public-text {
    margin-left: 10px;
    margin-top: 5px;
    color: var(--toco-color-text-base);
    font-size: 16px;
  }

  .info {
    display: flex;
    height: 100%;
    color: var(--toco-color-primary);
    padding-left: 9px;
  }

  .field-value {
    font-size: 14px;
    padding-left: 9px;
  }
}