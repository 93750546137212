@import "@df/workbench-common/src/styles/index.css";
@import '@vs/vs-table/dist/index.css';
@import './styles/index.css';

body {
    height: 100vh;
    width: 100vw;
    background-color: unset;   
}

a {
    color: var(--toco-color-primary) !important;
  }

.vs-base-table-container .ant-pro-table {
    width: 0;
}