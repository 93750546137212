.autocomplete-input {
  background-clip: padding-box;
  background-color: var(--toco-color-bg-base);
  border: 1px solid rgb(from var(--toco-color-text-base) r g b / 0.15);
  bottom: auto;
  display: block;
  font-size: 14px;
  list-style: none;
  padding: 1px;
  position: fixed;
  text-align: left;
  z-index: 20000;
  overflow-y: auto;
  margin-top: 1em;
}

.autocomplete-input > li {
 
 overflow: hidden;
 white-space: nowrap;
 text-overflow: ellipsis;
 padding: 0 12px;
 min-width: 150px;
 min-height: 24px;
 max-height: 24px;
 line-height: 24px;
 color: var(--toco-color-text-base);

 > b {
  color: var(--toco-color-primary);
 }
}

.autocomplete-input > li.active {
 background-color: rgb(from var(--toco-color-primary) r g b / 0.2);
}