.root {
  color: var(--toco-color-text-base);
  font-size: 14px;

  .permission {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
  }

  .owner {
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .editor {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .editor-label {
      font-size: 14px;
    }

    .editor-content {
    
    }
  }

  .list {
    height: calc(100vh - 94px);
    overflow-y: auto;
  }

  :global(.ant5-tabs) {
    color: unset !important;
  }  
  :global(.ant5-tabs-tab) {
    margin-left: 24px;
    font-size: 14px;
  }
}

.developer-name {
  display: flex;
  align-items: center;
  gap: 6px;
}