.drawer-section-header {
    .sub-dom {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .subTitle {
        margin-left: 10px;
        font-size: 14px;
        color: var(--toco-color-text-secondary);
        flex: 1;
        display: flex;
    }

    .extra{
        display: flex;
        align-items: center;
        gap: 8px;
    }
}