.root {
    .list {
      padding-left: 9px;
    }
  
    .text {
      color: var(--toco-color-text-base);
      font-size: 14px;
    }
  
    .info {
      display: flex;
      height: 100%;
      color: var(--toco-color-primary);
      padding-left: 9px;
    }
  
    .field-value {
      font-size: 14px;
      padding-left: 9px;
    }
  }