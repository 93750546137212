.footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--toco-color-border-custom);
    background-color: var(--toco-color-bg-layout);

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        flex: 1;
        overflow: hidden;

        .content-item {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: -999;
            transform: translate(-2000, -2000);
        }

        .active-content-item {
            opacity: 1;
            z-index: 1;
            transform: translate(0, 0);
        }
    }

    .bottom {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 32px;
        border-top: 1px solid var(--toco-color-border-custom);
        justify-content: flex-start;
        background-color: var(--toco-color-bg-base);

        .item {
            padding: 0 18px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }

        .active-item {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    .bottom-app {
        border-top-width: 0;
    }

    .part {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.footer-no-border {
    border-top: none;
}

.footer-electron {
    height: 100%;
    border-top-width: 0;
}