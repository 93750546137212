.toco-tips {
    position: fixed;
    display: flex;
    height: 26px;
    z-index: 9999;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    color: var(--toco-color-text-base);
    justify-content: center;
    align-items: center;
    background-color: rgba(251, 240, 218, 1);
    padding: 0 6px;

    .toco-word {
        margin-left: 4px;
        color: #333;
    }
}