/* H1 */
@define-mixin head-h1 $scroll-margin:20px {
    scroll-margin: $scroll-margin;
    height: 64px;
    line-height: 16px;
    font-size: 16px;
    color: var(--toco-color-text-base);
    padding-left: 10px;
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;

    &::before {
        position: absolute;
        top: 24px;
        left: 0;
        display: block;
        content: '';
        width: 4px;
        height: 16px;
        background: var(--toco-color-primary);
    }
}

/* H2 */
@define-mixin head-h2 $scroll-margin:20px {
    scroll-margin: $scroll-margin;
    font-size: 14px;
    color: var(--toco-color-text-base);
    position: relative;
    padding-left: 10px;
    height: 32px;
    font-weight: 500;
    margin-inline: 0;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 0;

    & ~ h2 {
        margin-top: 24px;
    }

    &::before {
        position: absolute;
        top: 8px;
        left: 0;
        display: block;
        content: '';
        border-left: 2px solid var(--toco-color-primary);
        width: 120px;
        height: 16px;
        background: linear-gradient(90deg, rgba(var(--toco-color-primary-value), 0.15) 0%, rgba(var(--toco-color-primary-value), 0) 100%);
    }
}
