.form {
  :global(.ant5-form-item-label) {
    width: 70px;
    
    label {
      font-size: 14px;
    }

    label:not(:global(.ant5-form-item-required)) {
      margin-left: 10px;
    }
  }

  :global(.ant5-form-item-control-input-content) {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
  :global(.ant5-select-selector) {
    font-size: 14px;
  }
  :global(.ant5-form-item-explain) {
    font-size: 14px;
  }
}