.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--toco-color-layout-bg-base);
  -webkit-app-region: drag;
  height: 40px;

  :global(.ant5-divider) {
    height: 22px;
    border-width: 2px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
  user-select: none;
  padding: 0 16px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background: var(--toco-color-layout-bg-base);
}

.logo {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: unset;
  margin-top: unset;

  .logoImage {
    height: 18px; 
  }
}

.server {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--toco-color-text-base);
  padding-right: 0 !important;
  -webkit-app-region: no-drag;
  font-weight: 400;
}

.button {
  color: var(--toco-color-primary);
  -webkit-app-region: no-drag;
  width: 28px;
  height: 28px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--toco-color-bg-selected) !important;
  }
}

.select {
  width: 300px;
  -webkit-app-region: no-drag;
}

.right {
  margin: 0 10px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  :global(.anticon) {
    color: var(--toco-color-primary);
  }
}

.counter {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--toco-color-primary);
}

.stack {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  position: relative;
  border: 1px solid var(--toco-color-border-secondary);
  border-top-width: 0;
  border-bottom-width: 0;
}

.stack-icon {
  width: 26px;
  height: 26px;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  -webkit-app-region: no-drag;
}

.stack-icon-clz {
  :global(.icon) {
    color: var(--toco-color-history-default) !important;
  }
}
.stack-icon-clz-disable {
  :global(.icon) {
    color: var(--toco-color-history-forbid) !important;
  }
}

.stack-icon-default {
  &:hover {
    background-color: var(--toco-color-bg-selected);
  }
}

.breadcrumb-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.resource-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.toco-breadcrumb {
  font-size: 14px;
  white-space: nowrap;
  -webkit-app-region: no-drag;

  :global(ol) {
    flex-wrap: nowrap;
  }

  :global(li:first-of-type) {
    width: 0;
    z-index: -1;
  }
  :global(li) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.toco-breadcrumb-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
/* 
.toco-breadcrumb-item-word {
  color: var(--toco-color-text-base);
} */

.toco-breadcrumb-item-icon {
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.toco-breadcrumb-item-extra {
  font-style: italic;
  margin-left: 6px;

  .readonly-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 6px;
  }

  :global(.word) {
    font-size: 12px;
  }
}

.toco-dictionary-modal {
  :global(.ant5-modal) {
    min-width: 750px;
  }

  :global(.ant5-modal-content) {
    padding: 0 !important;
  }

  :global(.ant5-modal-body) {
    height: 632px !important;
    max-height: 632px !important;
    min-height: 632px !important;
    overflow: hidden !important;
  }
}

.toco-server-select-modal {

  :global(.ant5-modal-content) {
    padding: 0;
  }

  :global(.ant5-modal-header) {
    height: 48px;
    padding: 0 24px;
    font-size: 16px;
    color: var(--toco-color-text-base);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--toco-color-border-secondary);
  }

  :global(.ant5-modal-close) {
    top: 8px;
    right: 12px;
  }

  :global(.ant5-modal-body) {
    padding: 20px 24px;
    border-bottom: 1px solid var(--toco-color-border-secondary);
    display: flex;
    overflow: hidden !important;
    position: relative;
  }

  :global(.ant5-modal-footer) {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    padding: 0 24px;
    margin: 0;

    :global(.ant5-btn) {
      height: 32px;
      width: 68px;
    }
  }
}

.toco-breadcrumb-module-icon-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-left: 4px;
}