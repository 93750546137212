
.cm-completionSection {
  font-size: 14px !important;
  padding-left: 12px !important;
  height: 24px !important;
  display: flex !important;
  align-items: center !important;
  background-color: var(--toco-color-bg-tooltip) !important;
}

.cm-tooltip-autocomplete ul li[aria-selected] {
  background-color: rgb(from var(--toco-color-primary) r g b / 0.2) !important;
  color: var(--toco-color-text-base) !important;
}

.cm-tooltip.cm-tooltip-autocomplete > ul {
  max-height: 15em !important;
}

.cm-tooltip.cm-tooltip-autocomplete > ul > li {
  line-height: 24px !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;

  .cm-completionLabel {
    margin-right: 100px;
  }

  .cm-completionDetail {
    flex: 1 !important;
    text-align: right !important;
    color: var(--toco-color-primary);
  }
}

.cm-tooltip.cm-tooltip-autocomplete > ul > div {
  background: var(--toco-color-bg-base) !important;
  padding-left: 12px;
  line-height: 30px;
}

.cm-completionIcon {
  line-height: 24px !important;
  margin-left: 12px !important;
  margin-right: 10px !important;
  padding: 0 !important;
  width: 12px !important;
  opacity: unset !important;
}

.cm-completionIcon::after {
  display: block;
  content: "" !important;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

.cm-completionIcon-constant::after {
  background-image: url('../../../../../public/sql/operator.png');
}

.cm-completionIcon-keyword::after {
  background-image: url('../../../../../public/sql/andor.png');
}

.cm-completionIcon-fieldbase::after {
  background-image: url('../../../../../public/sql/fieldbase.png');
}

.cm-completionIcon-fieldobject::after {
  background-image: url('../../../../../public/sql/fieldobject.png');
}

.cm-completionIcon-function::after {
  background-image: url('../../../../../public/sql/function.png');
}

.cm-completionIcon-variable::after {
  background-image: url('../../../../../public/sql/variable.png');
}

.cm-completionIcon-enum::after {
  background-image: url('../../../../../public/sql/enum.png');
}

.cm-completionIcon-parenthesis::after {
  background-image: url('../../../../../public/sql/parenthesis.png');
}
